import React, { useState, useContext, useEffect } from "react";
import Loading from "../Loading";
import { AuthContext } from "../context/Auth";
import { Navigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import firebase from "firebase/compat/app";
import firebaseApp, { db } from "../../firebase";
import { Controller, useForm } from "react-hook-form";
import LinkedInTag from "react-linkedin-insight";
import LogoLarge from "../styled/LogoLarge";

import bgFacility from "../../bgFacility.jpg";
import AuthWrapper from "./AuthWrapper";
import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Link,
  List,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import EmergencySearch from "components/EmergencySearch";
import useSearch from "hooks/useSearch";
import { PaidRounded, PeopleRounded, PhoneRounded, PlaceRounded } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import MuListItem from "components/mui/MuListItem";

// const VideoWrapper = styled.div`
//   position: absolute;
//   top: 0;
//   bottom: 0;
//   left: calc(100% + 5vw);
//   width: 350px;
//   height: 736px;
//   background-color: rgba(255, 255, 255, 0.9);
//   -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2),
//     0 4px 18px 0 rgba(0, 0, 0, 0.19);
//   box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2), 0 4px 18px 0 rgba(0, 0, 0, 0.19);
//   border-radius: 25px;
//   overflow: hidden;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin: auto;
//   opacity: ${({ $watchLevel }) => ($watchLevel !== "facility" ? 1 : 0)};
//   transition: opacity 300ms ease-in-out;

//   img {
//     width: 100%;
//     height: 100%;
//     object-fit: contain;
//   }

//   @media screen and (max-width: 1300px) {
//     left: calc(100% + 2vw);
//   }

//   @media screen and (max-width: 1140px) {
//     display: none;
//   }
// `;

const FacilityRegister = () => {
  const [loading, setLoading] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const address = useSearch("");
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm({
    mode: "onBlur",
    shouldUnregister: false,
  });
  const [firebaseErrors, setFirebaseErrors] = useState("");
  const [redirect, setRedirect] = useState(false);

  const watchFacility = watch("facility", "");

  const getAddress = ({ address, properties, text }) => {
    // Get the address of the clinic because Mapbox doesn't make it easy

    // Get the explicit address if Mapbox returns it
    if (properties.address) return properties.address;

    // Otherwise, get the address by piecing it together
    if (address) return `${address} ${text}`;

    // If we can't get anything, return an error
    return undefined;
  };

  const onSubmit = ({ email, password, address, facility, location, emergency_location }) => {
    setLoading(true);
    setFirebaseErrors("");
    setPageLoaded(true);

    // TODO: Show error if user selects a city instead of specific address

    // CREATE USER AND ADD TO DATABASE
    // Create user
    firebaseApp
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(({ user }) => {
        const { uid } = user;

        // Send verification email to user
        user
          .sendEmailVerification()
          .then(() => {
            let batch = db.batch();

            // Create user
            const userRef = db.collection("users").doc(uid);

            const locationId = facility === "hospital" ? String(emergency_location.id) : uid;
            const locationsRef = db.collection("locations").doc(locationId);

            // Add user information
            batch.set(userRef, {
              uid,
              email,
              role: "facility",
              location: locationId,
            });

            let zip;

            // If hospital, add newly created user to authorized users array
            if (facility === "hospital") {
              zip = emergency_location.zip;

              batch.update(locationsRef, {
                users: firebase.firestore.FieldValue.arrayUnion(uid),
              });
            }

            // If urgent care, add the new entry to location collection
            if (facility === "urgent-care") {
              const lookup = (name) => address.context.find((a) => a?.id.startsWith(name))?.text;
              const aAddress = getAddress(address);
              const aCity = lookup("place");
              const aState = lookup("region");
              zip = lookup("postcode");

              batch.set(locationsRef, {
                id: uid,
                title: location,
                lat: address.center[1],
                lng: address.center[0],
                address: aAddress,
                city: aCity,
                state: aState,
                zip,
                type: "Clinic",
                users: firebase.firestore.FieldValue.arrayUnion(uid),
                pending: true,
              });
            }

            // Add user as the owner of the zip code if the zip code is not already owned (does not already exist)
            if (zip) {
              db.collection("zips")
                .where("zip", "==", String(zip))
                .get()
                .then((querySnapshot) => {
                  if (querySnapshot.size === 0) {
                    db.collection("zips")
                      .doc(String(zip))
                      .set({
                        zip: String(zip),
                      });
                  }
                });
            }

            batch
              .commit()
              .then(() => {
                setRedirect(true);
                LinkedInTag.track(2177722); // Track purchase with LinkedIn analytics
              })
              .catch((error) => {
                setLoading(false);
                setFirebaseErrors(error.message);
                user.delete();
              });
          })
          .catch((error) => {
            setLoading(false);
            setFirebaseErrors(error.message);
            user.delete();
          });
      })
      .catch((error) => {
        setLoading(false);
        setFirebaseErrors(error.message);
      });
  };

  useEffect(() => {
    LinkedInTag.init(1439474);
  });

  const list = (
    <List dense={true}>
      <MuListItem
        icon={<PlaceRounded />}
        text="Manage your map listing"
        secondary="Give your visitors the info they need when looking at your facility."
      />

      <MuListItem
        icon={<PeopleRounded />}
        text="Speed up the wait time"
        secondary="Let visitors queue and register virtually before they even get to the facility."
      />

      {/* <MuListItem
        icon={<PaidRounded />}
        text="Pay as you go"
        secondary="Sign up for $1,499/month, or $6,000/year."
      /> */}

      <MuListItem
        icon={<PhoneRounded />}
        text="Includes onboarding conference call"
        secondary="Make sure everything is set up without a hitch with an onboarding call."
      />
    </List>
  );

  const { user } = useContext(AuthContext);

  if (user && !pageLoaded) {
    return <Navigate to="/dashboard" />;
  }

  if (redirect) {
    return <Navigate to="/dashboard" />;
  }

  if (user !== null && !pageLoaded) {
    return <Loading />;
  }

  return (
    <AuthWrapper background={bgFacility} title="Get on the Map" left facility>
      <LogoLarge />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="email"
          control={control}
          defaultValue={""}
          rules={{
            required: "Email is required.",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Invalid email address.",
            },
          }}
          render={({ field }) => (
            <TextField
              id="email"
              label="Email"
              type="email"
              InputLabelProps={{ shrink: true }}
              variant="standard"
              fullWidth
              error={!!errors?.email}
              helperText={errors?.email?.message}
              sx={{ pb: 2 }}
              {...field}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          defaultValue={""}
          rules={{
            required: "Password is required.",
            minLength: {
              value: 8,
              message: "Password must be at least 8 characters long.",
            },
            pattern: {
              value: /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/g,
              message:
                "Password must contain uppercase and lowercase letters, and at least one number or special character.",
            },
          }}
          render={({ field }) => (
            <TextField
              id="password"
              label="Password"
              type="password"
              InputLabelProps={{ shrink: true }}
              variant="standard"
              fullWidth
              error={!!errors?.password}
              helperText={errors?.password?.message}
              sx={{ pb: 2 }}
              {...field}
            />
          )}
        />

        <FormLabel>This facility is a:</FormLabel>
        <Controller
          name="facility"
          control={control}
          defaultValue={""}
          rules={{
            required: "Facility is required.",
          }}
          render={({ field: { onChange, value } }) => (
            <RadioGroup name="facility" value={value} onChange={onChange} sx={{ pb: 1 }}>
              <FormControlLabel
                value="hospital"
                label="Emergency Department (must be open 24 hours)"
                control={<Radio />}
              />
              <FormControlLabel
                value="urgent-care"
                label="Clinic/Immediate Care"
                control={<Radio />}
              />
            </RadioGroup>
          )}
        />

        {/* TODO: ADD ZIP TO HOSPITAL */}
        {watchFacility === "hospital" ? (
          <>
            <EmergencySearch
              control={control}
              errors={errors}
              // setValue={setValue}
              // setHospitalId={setHospitalId}
            />

            {list}
          </>
        ) : null}
        {watchFacility === "urgent-care" ? (
          <div>
            <Controller
              name="location"
              control={control}
              defaultValue={""}
              rules={{
                required: "Facility name is required.",
              }}
              render={({ field }) => (
                <TextField
                  id="location"
                  label="Facility Name"
                  type="text"
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                  fullWidth
                  error={!!errors?.location}
                  helperText={errors?.location?.message}
                  sx={{ pb: 2 }}
                  {...field}
                />
              )}
            />

            <Controller
              control={control}
              name="address"
              rules={{
                validate: {
                  required: (value) => {
                    if (!value) return "Facility address is required.";
                    if (!getAddress(value))
                      return "We weren't able to get an address from this location. Please choose a different address.";
                  },
                },
              }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  id="address"
                  options={address.suggestions}
                  onChange={(event, item) => {
                    onChange(item);
                  }}
                  value={value || null}
                  loading={address.loading}
                  noOptionsText={"No results found"}
                  getOptionLabel={(option) => option.place_name || ""}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Address"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {address.loading ? (
                              <CircularProgress color="primary" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                      onChange={(v) => address.onChange(v)}
                      variant="standard"
                      error={!!errors.address}
                    />
                  )}
                />
              )}
            />
            {errors?.address ? (
              <FormHelperText error={true}>{errors.address.message}</FormHelperText>
            ) : null}

            {list}
          </div>
        ) : null}

        {firebaseErrors ? <FormHelperText error={true}>{firebaseErrors}</FormHelperText> : null}

        <Controller
          name="privacy"
          control={control}
          rules={{
            required: "You must agree to the privacy policy.",
          }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} />}
              label={
                <>
                  I have read and agree to the{" "}
                  <a href="/privacy-policy" target="_blank">
                    Privacy Policy
                  </a>
                  .
                </>
              }
            />
          )}
        />

        {errors?.privacy ? (
          <FormHelperText error={true} sx={{ mb: 1 }}>
            {errors?.privacy?.message}
          </FormHelperText>
        ) : null}

        <Controller
          name="terms"
          control={control}
          rules={{
            required: "You must agree to the terms and services.",
          }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} />}
              label={
                <>
                  I have read and agree to the{" "}
                  <a href="/terms-of-use" target="_blank">
                    Terms and Services
                  </a>
                  .
                </>
              }
            />
          )}
        />

        {errors?.terms ? (
          <FormHelperText error={true} sx={{ mb: 1 }}>
            {errors?.terms?.message}
          </FormHelperText>
        ) : null}

        <LoadingButton
          type="submit"
          loading={loading}
          disabled={loading}
          variant="contained"
          fullWidth
          size="large"
          sx={{ mt: 2, mb: 2 }}
        >
          Sign Up
        </LoadingButton>
      </form>

      <Grid container>
        <Grid item xs>
          <Link component={RouterLink} to="/" underline="none" variant="body2">
            Return to Map
          </Link>
        </Grid>
        <Grid item>
          <Link component={RouterLink} to="/login" underline="none" variant="body2">
            Already have an account? Log in
          </Link>
        </Grid>
      </Grid>

      <Grid container sx={{ mt: 2 }}>
        <Grid item xs>
          <Link
            href="https://md3c.com/customize"
            underline="none"
            variant="body2"
            sx={{ fontWeight: "bold" }}
          >
            Promote Your Organization First (Learn More)
          </Link>
        </Grid>
        <Grid item></Grid>
      </Grid>
    </AuthWrapper>
  );
};

export default FacilityRegister;
