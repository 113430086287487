import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";

import Button from "components/styled/Button";
import Loading from "components/Loading";
import { FormHelperText, Grid, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const Wrapper = styled.div`
  overflow-y: hidden;
  max-height: ${({ $show }) => ($show ? "100px" : 0)};
  transition: 0.3s max-height ease-in-out;
`;

const Form = styled.form`
  margin-bottom: 10px;
  display: flex;
  align-items: flex-end;
  gap: 0.75rem;

  & > div {
    flex: 1;
  }
`;

const Label = styled.label`
  display: block;
  width: 100%;
  font-size: ${({ $large }) => ($large ? "14px" : "12px")};
`;

const Input = styled.input`
  display: block;
  width: 100%;
  background: #f6f6f6;
  border: 0;
  border-radius: 5px;
  height: 34px;
  font-size: 17px;
  font-weight: 400;
`;

const Error = styled.span`
  display: block;
  color: crimson;
  padding-bottom: 5px;
  font-size: 14px;
`;

const DiscountCode = ({ show, setTrial }) => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const onSubmit = ({ code }) => {
    setLoading(true);
    const studentDiscount = "STUDENT7";
    setError(false);
    setTrial(false);
    setSubmitted(false);

    setTimeout(() => {
      if (code === studentDiscount) {
        setSubmitted(true);
        setError(false);
        setTrial(true);
        setLoading(false);
      } else {
        setError(true);
        setLoading(false);
      }
    }, 500);
  };

  return (
    <Wrapper $show={show}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container fullWidth gap={2}>
          <Grid item>
            <Controller
              name="code"
              control={control}
              rules={{
                required: "A discount code is required.",
              }}
              render={({ field }) => (
                <TextField
                  id="code"
                  label="Discount Code"
                  type="text"
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                  fullWidth
                  error={!!errors?.code}
                  helperText={errors?.code?.message}
                  sx={{ mt: 2 }}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item sx={{ mt: 3.5 }}>
            <LoadingButton
              loading={loading}
              type="submit"
              disabled={loading}
              autoFocus
              variant="contained"
            >
              Apply
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
      <p style={{ marginTop: "0.25rem", marginBottom: "0.75rem" }}>
        {submitted && (
          <FormHelperText sx={{ color: "#1976D2" }}>
            Your code has been applied.
          </FormHelperText>
        )}

        {error ? (
          <FormHelperText error={true}>
            The code you entered is invalid or expired.
          </FormHelperText>
        ) : null}
      </p>
    </Wrapper>
  );
};

export default DiscountCode;
