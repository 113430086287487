import React from "react";
import styled from "styled-components";

const StyledLoading = styled.svg`
  position: ${({ $search }) => ($search ? "absolute" : "block")};
  top: ${({ $search }) => ($search ? "4px" : "auto")};
  right: ${({ $search }) => ($search ? "0.25em" : "auto")};
  animation: rotate 1s linear infinite;
  width: 28px;
  height: ${({ $page }) => ($page ? "100dvh" : "28px")};

  & .path {
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

const StyledPath = styled.circle`
  stroke: ${({ $page, $search, theme }) =>
    $page || $search ? theme.colors.primary : theme.colors.white};
`;

export default ({ page, search }) => (
  <StyledLoading $search={search} $page={page} viewBox="0 0 50 50">
    <StyledPath
      className="path"
      cx="25"
      cy="25"
      r="20"
      fill="none"
      strokeWidth="4"
      $page={page}
      $search={search}
    />
  </StyledLoading>
);
