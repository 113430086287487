import React, { useEffect, useState } from "react";
import firebaseApp, { db } from "../../firebase";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Chip,
  Box,
  Alert,
  CircularProgress,
  Link,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import capitalize from "components/helpers/capitalize";

const ZipTable = ({
  loading,
  subscription,
  subLoading,
  user,
  location,
  zips,
  setZips,
  setAddOpen,
  switchToBranding,
}) => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmed, setConfirmed] = useState();
  const [removingZip, setRemovingZip] = useState();
  const [radiusLoaded, setRadiusLoaded] = useState(false);
  const [nearbyZips, setNearbyZips] = useState();

  const formatPrice = (price) => {
    return price.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  // Since Firebase can only support up to 30 array items, split the array into groups of 30
  const splitArray = (inputArray) => {
    const perChunk = 30;

    const result = inputArray.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    }, []);

    return result;
  };

  const getZipsNearby = async () => {
    // Array for the zip codes before we fetch the population
    let tempZipCodes = [];

    // Get the nearby zips from the database
    db.collection("zips_near")
      .doc(String(location.zip))
      .get()
      .then(async (doc) => {
        if (doc.data()) {
          // Convert ZIP codes to numbers to deal with type mismatch in populations database
          tempZipCodes = doc.data().near.map((z) => Number(z));
          // Split the array so we can deal with arrays longer than 30
          tempZipCodes = splitArray(tempZipCodes);
          let batches = [];
          let zipsWithPopulation = [];

          tempZipCodes.forEach((chunk) => {
            batches.push(
              db
                .collection("populations")
                .where("zip", "in", chunk)
                .get()
                .then((querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    if (doc.data().population > 0) zipsWithPopulation.push(doc.data());
                  });
                })
            );
          });

          await Promise.all(batches);

          setNearbyZips(zipsWithPopulation);
        }
      });
  };

  const confirmRemoveZip = (zip) => {
    setConfirmOpen(true);
    setRemovingZip(zip);
  };

  const removeZip = () => {
    setConfirmed(true);

    // The current amount of zips, minus one
    const newQuantity = zips.length - 1;

    const zipToRemove = {
      zips,
      zip: [removingZip.zip],
      uid: user.uid,
      group: location.group,
      quantity: newQuantity,
      subId: subscription?.items[0]?.subscription,
      operation: "remove",
    };

    db.collection("users")
      .doc(user.uid)
      .collection("zipSubs")
      .doc()
      .set(zipToRemove)
      .then(() => {
        console.log("zip has been removed");
        setZips(zips.filter((z) => z.zip !== removingZip.zip));
        setConfirmed(false);
        setConfirmOpen(false);
        setRemovingZip();
      });
  };

  const close = () => {
    if (confirmed) return;
    setConfirmOpen(false);
  };

  useEffect(() => {
    if (location) getZipsNearby();
  }, [location]);

  if (loading || subLoading)
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );

  if (
    !loading &&
    location &&
    !location?.branding?.logo &&
    !location?.branding?.website &&
    !location?.branding?.tagline
  )
    return (
      <Alert severity="error">
        Your advertising will not show until you add your branding. Please{" "}
        <Link color="inherit" sx={{ cursor: "pointer" }} onClick={switchToBranding}>
          add your branding
        </Link>
        .
      </Alert>
    );

  return (
    <Box>
      {/* <Box sx={{ textAlign: "right" }}>
        <Button variant="contained" sx={{ mb: 2 }} onClick={() => setAddOpen(true)}>
          Add New ZIP
        </Button>
      </Box> */}

      {zips.length === 0 ? (
        <Alert severity="info">You don't currently have any ZIP codes you're advertising in.</Alert>
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ZIP Code</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {zips.map((zip) => (
                <TableRow key={zip.zip}>
                  <TableCell>{zip.zip}</TableCell>
                  <TableCell>
                    <Chip
                      size="small"
                      label={capitalize(zip.status)}
                      color={zip.status === "active" ? "success" : "default"}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell align="right">
                    <Button onClick={() => confirmRemoveZip(zip)} color="error" size="small">
                      Remove
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {nearbyZips?.length > 0 ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>ZIP Code</TableCell>
                <TableCell>Population</TableCell>
                <TableCell>Population-based Monthly Fee</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {nearbyZips?.map((zip) => (
                <TableRow key={zip.zip}>
                  <TableCell>{zip.zip}</TableCell>
                  <TableCell>{zip.population.toLocaleString("en-US")}</TableCell>
                  <TableCell>{formatPrice(zip.population * 0.05)}</TableCell>
                  <TableCell align="right">
                    <Button onClick={() => setAddOpen(zip)} color="primary" size="small">
                      Purchase
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}

      <Dialog open={confirmOpen} onClose={close}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Removing this ZIP will remove your advertisement from this ZIP code.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus disabled={confirmed} onClick={close}>
            Cancel
          </Button>
          <LoadingButton
            loading={confirmed}
            type="submit"
            disabled={confirmed}
            onClick={removeZip}
            color="error"
            variant="contained"
          >
            Yes, remove this ZIP
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ZipTable;
