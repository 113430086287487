export default [
  "Acarbose",
  "Acetaminophen \\(Paracetamol\\)",
  "Acetate \\(as part of parenteral nutrition for acid-base management\\)",
  "Acetazolamide",
  "Activated Charcoal \\(for various poisonings\\)",
  "Acyclovir",
  "Adenosine",
  "Adrenaline",
  "Albumin \\(used in conjunction with diuretics in the management of hypoalbuminemia-related edema\\)",
  "Albuterol \\(salbutamol\\)",
  "Albuterol \\(tablet form available\\)",
  "Alglucosidase Alfa",
  "Alteplase \\(tPA\\)",
  "Aluminum Hydroxide \\(for hyperphosphatemia\\)",
  "Amikacin",
  "Amiloride",
  "Aminophylline",
  "Aminophylline \\(primarily used IV, but oral forms exist\\)",
  "Amiodarone",
  "Amlodipine \\(Calcium channel blocker\\)",
  "Amoxicillin",
  "Amoxicillin-clavulanate",
  "Amphotericin B",
  "Amphotericin B \\(antifungal with antiviral activity against certain viruses, included for completeness\\)",
  "Anakinra",
  "Andexanet Alfa",
  "Anidulafungin",
  "Apixaban \\(DOAC\\)",
  "Argatroban \\(DTI\\)",
  "Argipressin",
  "Aripiprazole",
  "Articaine \\(primarily used in dental anesthesia\\)",
  "Asenapine",
  "Aspirin",
  "Atenolol \\(Beta-blocker, less commonly used IV\\)",
  "Atenolol \\(Beta-blocker\\)",
  "Atropine",
  "Atropine \\(for organophosphate poisoning\\)",
  "Azithromycin",
  "Aztreonam",
  "Baloxavir Marboxil",
  "Bambuterol",
  "Beclomethasone",
  "Beclomethasone \\(inhaled form, included for its use in asthma/COPD\\)",
  "Beclomethasone \\(oral inhalation for asthma\\)",
  "Benzocaine \\(topical\\)",
  "Betamethasone",
  "Bezlotoxumab",
  "Bivalirudin \\(DTI\\)",
  "Brexpiprazole",
  "Budesonide",
  "Budesonide \\(inhaled corticosteroid, included due to its role in chronic management\\)",
  "Budesonide \\(used in inflammatory bowel disease\\)",
  "Budesonide/Formoterol \\(combination inhaler\\)",
  "Budesonide/Formoterol \\(combination product\\)",
  "Bumetanide",
  "Bupivacaine",
  "Buprenorphine",
  "Butamben \\(topical\\)",
  "C1 Esterase Inhibitor",
  "Calcium Carbonate",
  "Calcium Chloride",
  "Calcium Citrate",
  "Calcium Gluconate",
  "Calcium Gluconate \\(for hydrofluoric acid burns or severe hypocalcemia\\)",
  "Canagliflozin",
  "Cangrelor",
  "Capsofungin",
  "Carbamazepine",
  "Carfilzomib",
  "Cariprazine",
  "Caspofungin",
  "Cefepime",
  "Cefotaxime",
  "Ceftaroline",
  "Ceftazidime",
  "Ceftazidime-avibactam",
  "Ceftriaxone",
  "Celecoxib",
  "Cephalexin",
  "Charcoal, Activated",
  "Chloroprocaine",
  "Chlorothiazide",
  "Chlorpromazine",
  "Chlorpromazine \\(less commonly used today due to side effect profile\\)",
  "Chlorthalidone",
  "Cholestyramine \\(for certain drug overdoses, binds toxins in the gut\\)",
  "Ciclesonide",
  "Ciclesonide \\(used for asthma management\\)",
  "Cidofovir",
  "Ciprofloxacin",
  "Clarithromycin",
  "Clevidipine",
  "Clindamycin",
  "Clobetasol \\(topical, listed for completeness in dermatologic conditions\\)",
  "Clonazepam",
  "Clonidine \\(Alpha-2 agonist\\)",
  "Clotrimazole \\(lozenges for oral thrush\\)",
  "Clozapine",
  "Clozapine \\(IV form is not widely used due to risk of agranulocytosis, mentioned for completeness\\)",
  "Cocaine \\(topical, used in some ENT procedures\\)",
  "Colestipol",
  "Conivaptan",
  "Cortisone",
  "Cortisone \\(converted to hydrocortisone in the body\\)",
  "Cromolyn Sodium \\(used in the management of allergic rhinitis and asthma\\)",
  "D-Penicillamine \\(for copper, lead, mercury, and arsenic poisoning\\)",
  "Dabigatran \\(DOAC\\)",
  "Dalbavancin",
  "Dalteparin \\(LMWH\\)",
  "Dantrolene",
  "Dapagliflozin",
  "Daptomycin",
  "Deferoxamine \\(for acute iron poisoning, though more commonly used IV\\)",
  "Deferoxamine \\(for iron poisoning\\)",
  "Deflazacort",
  "Dexamethasone",
  "Dexamethasone \\(used off-label for nausea and vomiting\\)",
  "Dexmedetomidine",
  "Dexrazoxane",
  "Diazepam",
  "Dibucaine \\(topical\\)",
  "Dichlorphenamide",
  "Diclofenac",
  "Digoxin",
  "Digoxin Fab Antibodies",
  "Digoxin Immune Fab \\(for digoxin overdose\\)",
  "Diltiazem",
  "Diltiazem \\(Calcium channel blocker\\)",
  "Dimenhydrinate",
  "Dimercaprol \\(for heavy metal poisoning, also available in IM form\\)",
  "Diphenhydramine \\(over-the-counter for sleep aid\\)",
  "Disopyramide",
  "Dobutamine",
  "Dobutamine \\(used in cases of heart failure with arrhythmias\\)",
  "Dopamine \\(at low doses, can have diuretic effects, although not primarily used as a diuretic\\)",
  "Doxepin \\(low dose for insomnia\\)",
  "Doxycycline",
  "Doxylamine",
  "Dronedarone",
  "Droperidol",
  "Droperidol \\(used for agitation, but with caution due to QT prolongation risk\\)",
  "Dyclonine \\(topical\\)",
  "Eculizumab",
  "Edoxaban \\(DOAC\\)",
  "Empagliflozin",
  "Enalaprilat \\(IV form of ACE inhibitor\\)",
  "Enoxaparin \\(LMWH\\)",
  "Entecavir \\(for hepatitis B\\)",
  "Ephedrine",
  "Epinephrine \\(for severe asthma attacks and anaphylaxis\\)",
  "Eplerenone",
  "Eptifibatide",
  "Ertapenem",
  "Esmolol",
  "Esmolol \\(Beta-blocker with fast onset\\)",
  "EsZopiclone",
  "Ethacrynic acid",
  "Ethosuximide",
  "Etidocaine",
  "Etomidate \\(primarily for induction of anesthesia\\)",
  "Exenatide \\(injectable, but listed here due to its role as a non-insulin antidiabetic\\)",
  "Famciclovir",
  "Fenoldopam \\(Dopamine D1 receptor agonist\\)",
  "Fentanyl",
  "Fentanyl \\(primarily an opioid analgesic with sedative properties\\)",
  "Fidaxomicin",
  "Flecainide",
  "Fluconazole",
  "Flucytosine \\(used in certain fungal infections\\)",
  "Flucytosine \\(used in combination with Amphotericin B for cryptococcal meningitis\\)",
  "Fludrocortisone \\(not typically used IV, included for completeness\\)",
  "Fludrocortisone \\(primarily for mineralocorticoid effects, but listed for completeness\\)",
  "Flumazenil \\(for benzodiazepine overdose\\)",
  "Flunisolide",
  "Fluocinonide \\(topical, but included for completeness in managing inflammatory skin conditions\\)",
  "Fluphenazine",
  "Fluphenazine \\(long-acting injectable form available\\)",
  "Fluticasone",
  "Fluticasone furoate",
  "Fluticasone/Salmeterol \\(combination inhaler, primarily for COPD/asthma\\)",
  "Fluticasone/Salmeterol \\(combination product\\)",
  "Folic Acid",
  "Fomepizole \\(for methanol or ethylene glycol poisoning\\)",
  "Fondaparinux",
  "Formoterol \\(tablet form for COPD\\)",
  "Formoterol \\(used in COPD\\)",
  "Foscarnet",
  "Fosphenytoin",
  "Furosemide",
  "Furosemide \\(Loop diuretic\\)",
  "Gabapentin",
  "Ganciclovir",
  "Gentamicin",
  "Glimepiride",
  "Glipizide",
  "Glucagon \\(for beta-blocker or calcium channel blocker overdose\\)",
  "Glucagon \\(used IV for severe hypoglycemia\\)",
  "Glucarpidase",
  "Glucose Solutions",
  "Glyburide",
  "Glycopyrrolate",
  "Glycopyrronium",
  "Granisetron",
  "Griseofulvin",
  "Haloperidol",
  "Haloperidol \\(used off-label for nausea and vomiting\\)",
  "Heparin",
  "Heparin \\(Unfractionated Heparin\\)",
  "Hexylcaine",
  "Hydralazine \\(Vasodilator\\)",
  "Hydrochloric Acid \\(for severe metabolic alkalosis\\)",
  "Hydrochlorothiazide",
  "Hydrochlorothiazide \\(Thiazide diuretic\\)",
  "Hydrocodone",
  "Hydrocortisone",
  "Hydrocortisone \\(less commonly used orally, more for adrenal insufficiency\\)",
  "Hydromorphone",
  "Hydroxocobalamin",
  "Hydroxyzine",
  "Hypertonic Saline",
  "Ibuprofen",
  "Ibutilide",
  "Icatibant",
  "Idarucizumab",
  "Iloperidone",
  "Imipenem-cilastatin",
  "Indacaterol \\(capsule for inhalation, but included here for completeness\\)",
  "Indapamide",
  "Insulin aspart",
  "Insulin degludec \\(not typically used IV, included for completeness\\)",
  "Insulin detemir \\(not typically used IV, but included for completeness\\)",
  "Insulin glargine \\(not typically used IV, but included for completeness\\)",
  "Insulin glulisine",
  "Insulin lispro",
  "Insulin with Glucose \\(for hyperkalemia\\)",
  "Intravenous Immunoglobulin \\(IVIG\\) \\(broad-spectrum antiviral properties, used in severe infections or immune deficiencies\\)",
  "Ipratropium bromide",
  "Isavuconazole",
  "Isoproterenol",
  "Isosorbide Dinitrate",
  "Itraconazole",
  "Itraconazole \\(IV formulation for severe infections\\)",
  "IV Antidiabetics",
  "IV Antiemetics",
  "IV Antivirals",
  "IV Corticosteroids",
  "IV Diuretics",
  "Ketamine \\(also used for anesthesia and pain management\\)",
  "Ketoconazole",
  "Ketoconazole \\(historically used IV, but now less common due to liver toxicity risks\\)",
  "Ketorolac",
  "Labetalol",
  "Labetalol \\(Alpha and beta-blocker\\)",
  "Lacosamide",
  "Lactated Ringer's",
  "Lamisil \\(another name for Terbinafine, commonly used for nail infections\\)",
  "Lamivudine \\(for hepatitis B and HIV\\)",
  "Lamotrigine",
  "Ledipasvir/Sofosbuvir \\(for hepatitis C\\)",
  "Letermovir \\(for cytomegalovirus in transplant recipients\\)",
  "Levalbuterol",
  "Levetiracetam",
  "Levobupivacaine",
  "Levocarnitine",
  "Levofloxacin",
  "Levosimendan",
  "Lidocaine",
  "Linagliptin",
  "Linezolid",
  "Liposomal Amphotericin B \\(a formulation designed to reduce toxicity\\)",
  "Liraglutide \\(injectable, included for completeness in the category of GLP-1 receptor agonists\\)",
  "Lisinopril \\(ACE inhibitor\\)",
  "Lixisenatide",
  "Loop Diuretics \\(general category, including furosemide, bumetanide, torsemide\\)",
  "Lorazepam",
  "Losartan \\(Angiotensin II receptor blocker\\)",
  "Loxapine \\(inhaled version available for acute agitation\\)",
  "Lurasidone",
  "Magnesium Oxide",
  "Magnesium sulfate",
  "Magnesium Sulfate \\(for eclampsia\\)",
  "Magnesium sulfate \\(used as a bronchodilator in severe asthma exacerbations\\)",
  "Mannitol",
  "Mannitol \\(primarily used IV, but listed here due to its diuretic properties\\)",
  "Meclizine",
  "Melatonin \\(for sleep disorders\\)",
  "Meperidine",
  "Mepivacaine",
  "Meropenem",
  "Metaproterenol",
  "Metformin",
  "Methohexital \\(primarily for induction of anesthesia\\)",
  "Methylene Blue",
  "Methylene Blue \\(for methemoglobinemia\\)",
  "Methylnaltrexone",
  "Methylprednisolone",
  "Methylthioninium chloride \\(Methylene Blue\\) \\(for methemoglobinemia, though more commonly used IV\\)",
  "Metoclopramide",
  "Metolazone",
  "Metoprolol \\(Beta-blocker\\)",
  "Metronidazole",
  "Mexiletine",
  "Micafungin",
  "Miconazole \\(primarily topical, but oral gel available for oral thrush\\)",
  "Midazolam",
  "Miglitol",
  "Milrinone",
  "Misoprostol",
  "Mometasone",
  "Mometasone/formoterol \\(combination product\\)",
  "Montelukast",
  "Morphine",
  "Moxifloxacin",
  "N-acetylcysteine",
  "N-acetylcysteine \\(for acetaminophen overdose\\)",
  "Nalbuphine",
  "Nalmefene",
  "Naloxone \\(for opioid overdose\\)",
  "Naproxen",
  "Nateglinide",
  "Nebulized Bronchodilators",
  "Nedocromil \\(for asthma management\\)",
  "Neostigmine",
  "Nicardipine",
  "Nicardipine \\(Calcium channel blocker\\)",
  "Nifedipine \\(Calcium channel blocker\\)",
  "Nitrofurantoin",
  "Nitroglycerin \\(Vasodilator for acute coronary syndrome\\)",
  "Nitrous Oxide",
  "Norepinephrine",
  "Nystatin \\(mainly for oral thrush\\)",
  "Octreotide",
  "Olanzapine",
  "Ondansetron",
  "Oral rehydration solutions \\(for dehydration due to toxin-induced diarrhea\\)",
  "Oritavancin",
  "Oseltamivir",
  "Oseltamivir \\(IV formulations under compassionate use or trials\\)",
  "Oxcarbazepine",
  "Oxycodone",
  "Oxytocin",
  "Paliperidone",
  "Paliperidone Palmitate \\(long-acting injectable form\\)",
  "Palonosetron",
  "Pantoprazole",
  "Penicillin V",
  "Peramivir",
  "Perphenazine",
  "Phenobarbital",
  "Phenobarbital \\(for severe agitation or seizure management\\)",
  "Phenol \\(topical, also has antiseptic properties\\)",
  "Phenylephrine",
  "Phenytoin",
  "Phosphate Supplements \\(e.g., K-Phos\\)",
  "Phosphorus",
  "Physostigmine",
  "Pioglitazone",
  "Piperacillin-tazobactam",
  "Pneumococcal Polysaccharide Vaccine",
  "Posaconazole",
  "Posaconazole \\(IV formulation for severe infections\\)",
  "Potassium Chloride",
  "Potassium Citrate",
  "Potassium Iodide \\(for radiation exposure\\)",
  "Pralidoxime",
  "Pralidoxime \\(2-PAM\\) \\(for organophosphate poisoning\\)",
  "Prazosin",
  "Prednisolone",
  "Prednisone",
  "Prednisone \\(less commonly used IV as it's typically administered orally\\)",
  "Prednisone \\(not a bronchodilator per se, but used orally in the management of airway inflammation\\)",
  "Pregabalin",
  "Prilocaine",
  "Procainamide",
  "Procaine",
  "Prochlorperazine",
  "Promethazine",
  "Propafenone",
  "Propofol",
  "Propranolol \\(Beta-blocker\\)",
  "Prostaglandin E1",
  "Protamine Sulfate",
  "Quetiapine",
  "Quetiapine \\(off-label use for insomnia\\)",
  "Quinidine",
  "Raltegravir \\(for HIV\\)",
  "Ramelteon",
  "Ramipril \\(ACE inhibitor\\)",
  "Ravulizumab",
  "Regular insulin \\(IV use for acute hyperglycemia\\)",
  "Remdesivir",
  "Remdesivir \\(for COVID-19\\)",
  "Remifentanil",
  "Remifentanil \\(opioid analgesic with sedative properties, used in anesthesia\\)",
  "Repaglinide",
  "Reteplase",
  "Ribavirin",
  "Ribavirin \\(for hepatitis C and RSV\\)",
  "Rifampin",
  "Risperidone",
  "Risperidone Consta \\(long-acting injectable form\\)",
  "Rivaroxaban \\(DOAC\\)",
  "Rocuronium",
  "Roflumilast",
  "Ropivacaine",
  "Rosiglitazone",
  "Salbutamol \\(less common but used in severe cases\\)",
  "Salmeterol \\(oral inhalation powder\\)",
  "Sarilumab",
  "Saxagliptin",
  "Sevelamer",
  "Sevoflurane",
  "Sildenafil",
  "Sitagliptin",
  "Sodium Bicarbonate",
  "Sodium Chloride \\(normal saline, hypertonic saline\\)",
  "Sodium Chloride \\(table salt, but used in medical formulations for hyponatremia\\)",
  "Sodium Nitroprusside \\(Vasodilator for hypertensive emergencies\\)",
  "Sodium Polystyrene Sulfonate \\(for hyperkalemia\\)",
  "Sodium Thiosulfate",
  "Sofosbuvir \\(for hepatitis C\\)",
  "Sotalol",
  "Spironolactone",
  "Spironolactone \\(Aldosterone antagonist\\)",
  "Spironolactone \\(primarily oral, but IV form exists for specific indications\\)",
  "Streptokinase",
  "Succimer \\(for lead poisoning\\)",
  "Succinylcholine",
  "Sufentanil",
  "Sufentanil \\(similarly, an opioid with use in anesthesia\\)",
  "Sugammadex",
  "Sulfamethoxazole",
  "Tedizolid",
  "Temazepam",
  "Tenecteplase",
  "Tenofovir \\(for hepatitis B and HIV\\)",
  "Terbinafine",
  "Terbutaline",
  "Terlipressin",
  "Tetracaine",
  "Tetracycline",
  "Theophylline",
  "Thiamine",
  "Thiazide Diuretics \\(general category, including hydrochlorothiazide, chlorthalidone\\)",
  "Thiopental \\(less commonly used, primarily for induction of anesthesia\\)",
  "Thioridazine",
  "Thioridazine \\(not commonly used IV, included for completeness\\)",
  "Tigecycline",
  "Tiotropium bromide",
  "Tobramycin",
  "Tocilizumab",
  "Tolvaptan",
  "Topiramate",
  "Torsemide",
  "Tramadol",
  "Tranexamic Acid",
  "Trazodone \\(off-label use for insomnia\\)",
  "Triamcinolone",
  "Triamcinolone \\(less common, more for intra-articular or soft tissue injection\\)",
  "Triamcinolone \\(oral forms for specific conditions\\)",
  "Triamterene",
  "Trifluoperazine",
  "Trimethoprim/sulfamethoxazole \\(TMP-SMX\\)",
  "Ulipristal",
  "Umeclidinium and Vilanterol \\(combination used for COPD\\)",
  "Urokinase",
  "Valacyclovir",
  "Valproic Acid",
  "Vancomycin",
  "Verapamil",
  "Vitamin K \\(for warfarin overdose\\)",
  "Voriconazole",
  "Warfarin",
  "Zanamivir \\(inhaled\\)",
  "Zidovudine \\(for HIV, during labor to prevent mother-to-child transmission\\)",
  "Ziprasidone",
  "Zolpidem",
  "Zonisamide",
];
