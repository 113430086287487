import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import firebaseApp, { db } from "../../firebase";
import { Controller } from "react-hook-form";
import capitalize from "components/helpers/capitalize";

const ZipPayment = ({ user, paymentMethod, setPaymentMethod }) => {
  const [loading, setLoading] = useState(true);
  const [paymentMethods, setPaymentMethods] = useState([]);

  const getMethods = () => {
    db.collection("users")
      .doc(user.uid)
      .collection("payment_methods")
      .add({ customer: user.stripeId })
      .then((docRef) => {
        docRef.onSnapshot((snap) => {
          const { error, data } = snap.data();
          if (error) {
            alert(`An error occurred: ${error.message}`);
          }

          if (data) {
            setPaymentMethods(data);

            // Set the first payment method as the selected one
            if (data.length > 0) setPaymentMethod(data[0].id);

            setLoading(false);
          }
        });
      });
  };

  useEffect(() => {
    getMethods();
  }, []);

  return (
    <Box sx={{ mt: 3, textAlign: "center" }}>
      {/* TODO: Check if the payment method is expired */}
      {loading ? (
        <CircularProgress />
      ) : (
        <Box sx={{ textAlign: "left" }}>
          {paymentMethods.length > 0 ? (
            <>
              <Typography
                variant="body1"
                sx={{ mt: 2, mb: 2, fontWeight: "bold" }}
              >
                Please select the payment method you intend to make the bid
                with.
              </Typography>

              <FormControl fullWidth variant="standard">
                <InputLabel id="payment-method" shrink={true}>
                  Payment Method
                </InputLabel>
                <Select
                  labelId="payment-method"
                  label="Payment Method"
                  value={paymentMethod}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                >
                  {paymentMethods.map((method) => (
                    <MenuItem value={method.id} key={method.id}>
                      {capitalize(method.card.brand)} ending in{" "}
                      {method.card.last4}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Typography variant="body2" sx={{ mt: 2 }}>
                To add a new payment method, click "Manage Subscription" under
                your account settings.
              </Typography>
            </>
          ) : (
            <Typography variant="body2" sx={{ mt: 2 }}>
              You have no payment methods available. To add a new payment
              method, click "Manage Subscription" under your account settings.
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ZipPayment;
