import React from "react";
import styled from "styled-components";
import useSearch from "hooks/useSearch";
import { Autocomplete, Box, CircularProgress, TextField } from "@mui/material";

export default ({
  setCoords,
  setAddress,
  addressField = false,
  searchLoaded = true,
  open = true,
  setCustomLocation,
}) => {
  const address = useSearch("");

  return (
    <Box
      sx={{
        position: "relative",
        maxHeight: open ? "40px" : 0,
        overflowY: "hidden",
        overflowX: "hidden",
        transition: "max-height 200ms",
      }}
    >
      <Autocomplete
        id="address"
        options={address.suggestions}
        loading={address.loading}
        noOptionsText={"No results found"}
        getOptionLabel={(option) => option.place_name || ""}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={(e, location) => {
          if (location) {
            const lat = location.center[1];
            const lng = location.center[0];

            // Get the ZIP code. There's a couple of ways it might be revealed through mapbox so we have to parse them
            const contextZip = location.context.find((a) =>
              a?.id.startsWith("postcode")
            )?.text;
            const placenameZip =
              location.place_name.match(/\d{5}(?:[-\s]\d{4})?/g);

            let zip;
            if (contextZip || placenameZip)
              zip = contextZip || placenameZip[placenameZip?.length - 1];

            setAddress && setAddress(location.place_name);
            setCoords && setCoords({ lat, lng });
            setCustomLocation && setCustomLocation({ lat, lng, zip });
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={`Search for ${
              addressField ? "an" : "a city or"
            } address`}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              endAdornment: (
                <React.Fragment>
                  {address.loading || !searchLoaded ? (
                    <CircularProgress color="primary" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            onChange={(v) => address.onChange(v)}
            variant="standard"
          />
        )}
      />
    </Box>
  );
};

//   return (
//     <>
//       <Wrapper $open={open}>
//         <Input
//           placeholder={`Search for ${
//             addressField ? "an" : "a city or"
//           } address`}
//           value={address.value}
//           onChange={address.onChange}
//           onFocus={() => {
//             setTimeout(() => {
//               setHitsOpen(true);
//             }, 100);
//           }}
//           onBlur={() => {
//             setTimeout(() => {
//               setHitsOpen(false);
//             }, 200);
//           }}
//           autoFocus
//         />
//         {address.loading || !searchLoaded ? <Loading search /> : null}
//       </Wrapper>
//       {address.suggestions?.length > 0 && (
//         <SuggestionWrapper className="suggestions" $hitsOpen={hitsOpen}>
//           {address.suggestions.map((suggestion, index) => {
//             return (
//               <Suggestion
//                 key={index}
//                 onClick={() => {
//                   const { place_name } = suggestion;
//                   const lat = suggestion.center[1];
//                   const lng = suggestion.center[0];

//                   address.setValue(place_name);
//                   setAddress && setAddress(place_name);
//                   setCoords && setCoords({ lat, lng });
//                   address.setSuggestions([]);
//                   updateLocation && updateLocation({ lat, lng }, place_name);
//                 }}
//               >
//                 <span className="title">{suggestion.text}</span>
//                 <span className="place">{suggestion.place_name}</span>
//               </Suggestion>
//             );
//           })}
//         </SuggestionWrapper>
//       )}
//     </>
//   );
// };

const Wrapper = styled.div`
  /* margin: 0 auto; */
  position: relative;
  max-height: ${({ $open }) => ($open ? "40px" : 0)};
  overflow-y: hidden;
  overflow-x: hidden;
  transition: max-height 200ms;
`;

const Input = styled.input`
  width: 100%;
  background: transparent;
  border: none;
  padding: 10px 0;
  position: relative;
  display: grid;
  justify-self: center;
  font-size: 16px;

  &:focus {
    outline: none;
  }
`;

const SuggestionWrapper = styled.div`
  background: white;
  position: absolute;
  width: calc(100% + 10px);
  padding: 10px 0;
  margin-left: -5px;
  z-index: 99;
  visibility: ${({ $hitsOpen }) => ($hitsOpen ? "visible" : "hidden")};
`;

const Suggestion = styled.p`
  cursor: pointer;
  color: #1b4584;
  font-size: 16px !important;
  font-weight: 500 !important;
  text-align: left !important;
  max-width: 100%;
  padding: 7px 20px;
  margin: 0 !important;

  .title {
    display: block;
    font-weight: 700 !important;
  }

  .place {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }

  &:hover {
    background-color: #f9f9f9;
  }
`;
