import React, { useEffect, useState } from "react";
import firebaseApp, { db } from "../../firebase";
import firebase from "firebase/compat/app";
import "firebase/compat/functions";
import "firebase/compat/storage";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Box,
  Alert,
  Link,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ZipTable from "./ZipTable";

import { useStripe } from "@stripe/react-stripe-js";

const PromoteFacilitiesSettings = ({
  user,
  location,
  switchToAddress,
  switchToBranding,
  open,
  close,
}) => {
  const [loading, setLoading] = useState(true);
  const [addOpen, setAddOpen] = useState(false);
  const [zipsAdded, setZipsAdded] = useState(false);
  const [subLoading, setSubLoading] = useState(true);
  const [subscription, setSubscription] = useState();
  const [zips, setZips] = useState([]);

  // Check to see if we have a Stripe subscription yet, if yes, then no need to go through the whole Stripe checkout process again
  const getSubscription = () => {
    if (!user || subscription) return;

    // subscription will be null if not loaded yet
    if (subLoading == true)
      db.collection("users")
        .doc(user.uid)
        .collection("subscriptions")
        .where("role", "==", "promote_facilities")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            setSubscription(doc.data());
          });

          setSubLoading(false);
        });
  };

  const getZips = () => {
    if (!location || !user) return;

    setZips(user.zipAds?.filter((z) => z.status !== "pending") || []);

    // db.collection("zips")
    //   .where("group_users", "array-contains", user.uid)
    //   .get()
    //   .then((querySnapshot) => {
    //     let docs = [];

    //     querySnapshot.forEach(function (doc) {
    //       if (doc.data()) {
    //         const data = doc.data();

    //         docs.push(data);
    //       }
    //     });

    // setZips(docs);
    setLoading(false);
    // });
  };

  useEffect(() => {
    if (open) getZips();
  }, [open, location, user]);

  // Check if we have a promote_facilities subscription that exists yet
  useEffect(() => {
    if (open) getSubscription();
  }, [open, location, user]);

  return (
    <>
      <Dialog open={open} onClose={close} maxWidth="md" fullWidth>
        <DialogTitle>Promote Your Brand on the HLTHDSK Map</DialogTitle>

        <DialogContent>
          <DialogContentText variant="body2" sx={{ mb: 2 }}>
            Feature your institution's name, logo, tagline, and website on the HLTHDSK map within
            the ZIP codes of your choice. In addition, HLTHDSK will actively promote your presence
            in those selcted areas, ensuring enhanced visibility and engagement with your target
            audience.
          </DialogContentText>

          {zipsAdded ? (
            <Alert severity="success" sx={{ mt: 3, mb: 4 }}>
              Your advertisements on your selected ZIP codes have been posted.
            </Alert>
          ) : null}

          {!loading && !location?.group ? (
            <Alert severity="error">
              To advertise your facilities, please{" "}
              <Link color="inherit" sx={{ cursor: "pointer" }} onClick={switchToAddress}>
                enter the name of your Facility Group.
              </Link>
            </Alert>
          ) : (
            <ZipTable
              loading={loading}
              subLoading={subLoading}
              subscription={subscription}
              user={user}
              location={location}
              zips={zips}
              setZips={setZips}
              setAddOpen={setAddOpen}
              switchToBranding={switchToBranding}
            />
          )}

          <AddZipCode
            user={user}
            subscription={subscription}
            location={location}
            setZipsAdded={setZipsAdded}
            open={addOpen}
            close={() => setAddOpen(false)}
            zips={zips}
            setZips={setZips}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={close} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const AddZipCode = ({ user, subscription, location, open, close, zips, setZips, setZipsAdded }) => {
  const stripe = useStripe();
  const {
    control,
    handleSubmit,
    formState: { errors },
    unregister,
  } = useForm();
  const [loading, setLoading] = useState(false);

  const [newZips, setNewZips] = useState(1);

  const addNewZip = () => {
    if (newZips > 9) return;
    setNewZips(newZips + 1);
  };

  const removeNewZip = () => {
    if (newZips === 1) return;

    unregister("zip_" + newZips);
    setNewZips(newZips - 1);
  };

  const onSubmit = () => {
    console.log(open);

    // const slots = Object.keys(submittedZips).length;

    setLoading(true);

    // If a stripe subscription already exists, just update the quantity through the backend function
    // if (subscription) {
    //   const newQuantity = zips.length + slots;

    //   const zipsToAdd = {
    //     zips,
    //     zip: Object.values(submittedZips),
    //     uid: user.uid,
    //     group: { name: location.group, ...location.branding },
    //     branding: location.branding,
    //     quantity: newQuantity,
    //     subId: subscription.items[0].subscription,
    //     operation: "add",
    //   };

    //   const newZips = Object.values(submittedZips).map((z) => {
    //     return { zip: z, status: "active" };
    //   });

    //   db.collection("users")
    //     .doc(user.uid)
    //     .collection("zipSubs")
    //     .doc()
    //     .set(zipsToAdd)
    //     .then(() => {
    //       setZips(zips.concat(newZips).sort((a, b) => b - a));
    //       setZipsAdded(true);
    //       close();
    //     });
    // }

    const price = open.population * 0.05 * 100;

    // If there is no subscription yet we have to take the customer to the checkout portal, otherwise just adjust the quantity
    if (!subscription) {
      const checkout = {
        price,
        // quantity: slots,
        payment_method_collection: "if_required",
        success_url: window.location.origin + "/dashboard/advertising/success", // redirect user to this screen after
        cancel_url: window.location.origin + "/dashboard/advertising",
      };

      const pendingZips = [open.zip].map((z) => {
        return {
          zip: z,
          status: "pending",
          group: location.group,
          branding: location.branding,
        };
      });

      db.collection("users")
        .doc(user.uid)
        .update({
          zipAds: pendingZips,
        })
        .then(() => {
          db.collection("users")
            .doc(user.uid)
            .collection("advertising_checkout_sessions")
            .add(checkout)
            .then((docRef) => {
              docRef.onSnapshot((snap) => {
                const { error, sessionId } = snap.data();
                if (error) {
                  alert(`An error occurred: ${error.message}`);
                }

                if (sessionId) {
                  stripe.redirectToCheckout({ sessionId });
                }
              });
            });
        });
    }
    // TODO: Handle errors with catch
  };

  return (
    <Dialog open={open} onClose={close}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Add ZIP Code</DialogTitle>

        <DialogContent>
          <DialogContentText variant="body2" sx={{ mb: 2 }}>
            Purchase this ZIP code to have your facilities be shown as a category to all users in
            the area.
          </DialogContentText>

          <DialogContentText variant="body2" sx={{ mb: 2 }}>
            You will be taken to Stripe to complete your payment.
          </DialogContentText>

          {/* {Array.from(Array(newZips), (e, i) => {
            const num = i + 1;

            return (
              <Controller
                key={`zip_${num}`}
                name={`zip_${num}`}
                control={control}
                rules={{
                  required: "ZIP code is required.",
                  pattern: {
                    value: /^\d{5}?$/i,
                    message: "Please enter a valid 5-digit ZIP code.",
                  },
                }}
                defaultValue={""}
                render={({ field }) => (
                  <TextField
                    id="zip"
                    label={newZips > 1 ? `ZIP Code #${num}` : `ZIP Code`}
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                    fullWidth
                    error={!!errors?.["zip_" + num]}
                    helperText={errors?.["zip_" + num]?.message}
                    sx={{ mt: 2 }}
                    {...field}
                  />
                )}
              />
            );
          })} */}

          {/* {newZips < 10 ? (
            <Box sx={{ mt: 2 }}>
              <Button disabled={loading} onClick={addNewZip} variant="contained" sx={{ mr: 2 }}>
                Add Another ZIP
              </Button>

              {newZips > 1 ? (
                <Button disabled={loading} onClick={removeNewZip} variant="text">
                  Remove ZIP
                </Button>
              ) : null}
            </Box>
          ) : null} */}

          {open ? (
            <Box sx={{ textAlign: "right", mt: 2, mb: 0 }}>
              <Typography variant="body">
                <strong>You will be billed ${open.population * 0.05}/month.</strong>
              </Typography>
            </Box>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={close}>
            Close
          </Button>
          {!subscription ? (
            <LoadingButton
              loading={loading}
              type="submit"
              disabled={loading}
              autoFocus
              variant="contained"
            >
              Proceed to Payment
            </LoadingButton>
          ) : (
            <LoadingButton
              loading={loading}
              type="submit"
              disabled={loading}
              autoFocus
              variant="contained"
            >
              Add ZIP Codes
            </LoadingButton>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default PromoteFacilitiesSettings;
