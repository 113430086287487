import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import styled from "styled-components";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

import useWidth from "../hooks/useWidth";

import Icon from "./styled/Icon";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 920px;
  overflow-x: hidden;

  .button-wrapper {
    padding: 0 10px;
  }
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ $noMargin }) => ($noMargin ? "auto" : "100%")};
  border-radius: 4px;
  height: ${({ $small }) => ($small ? "2.6em" : "2.8em")};
  font-size: ${({ $small }) => ($small ? "14px" : "16px")};
  border: 0;
  color: ${(props) =>
    props.gray ? props.theme.colors.darkgray : props.theme.colors.white};
  background-color: ${(props) =>
    props.$secondary
      ? props.theme.colors.secondary
      : props.gray
      ? props.theme.colors.gray
      : props.theme.colors.primary};
  margin: 0.4rem 0 1.4rem;
  padding: 0 ${({ $small }) => ($small ? "0.5em" : "1em")};
  cursor: pointer;
`;

const Viewer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors.white};
  overflow-y: scroll;
  display: ${(props) => (props.visible ? "initial" : "none")};

  .navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2em 0 1em;
  }

  @media (min-width: 479px) {
    .mobile {
      display: none;
    }
  }
`;

const ClosePopup = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding: 12px;
  font-size: 30px;
  line-height: 20px;
  cursor: pointer;
  z-index: 999;
`;

const PDFViewer = ({ pdf, visible, setVisible }) => {
  const width = useWidth();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <Viewer visible={visible}>
      <div className="navigation">
        <Icon
          icon={<FaChevronLeft />}
          onClick={() => {
            if (pageNumber > 1) setPageNumber(pageNumber - 1);
          }}
        />
        Page {pageNumber}/{numPages}
        <Icon
          icon={<FaChevronRight />}
          onClick={() => {
            if (pageNumber < numPages) setPageNumber(pageNumber + 1);
          }}
        />
      </div>
      <Wrapper>
        <TransformWrapper wheel={{ disabled: true }}>
          <TransformComponent>
            <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
              <Page
                pageNumber={pageNumber}
                renderMode="svg"
                width={Math.min(width, 920)}
              />
            </Document>
          </TransformComponent>
        </TransformWrapper>
        <div className="button-wrapper">
          <Button onClick={() => setVisible(false)}>Reviewed</Button>
        </div>
      </Wrapper>
      <div className="navigation mobile">
        <p>(Pinch to zoom in)</p>
      </div>
    </Viewer>
  );
};

export default PDFViewer;
