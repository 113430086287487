import React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import Button from "components/styled/Button";
import Loading from "components/Loading";

const Columns = styled.div`
  display: flex;
  align-items: flex-start;

  * {
    flex-basis: 5%;
  }

  span {
    margin-right: auto;
    flex-basis: 85%;
    font-size: ${({ $small }) => ($small ? "14px" : "20px")};
  }

  label {
    font-size: 12px;
    font-weight: bold;
    text-align: center;
  }

  input {
    margin: 0;
    padding: 10px;
  }

  margin-bottom: 0.75em;
`;

const RadioField = ({ name, label, register, small, number, patient }) => (
  <Columns $small={small}>
    <span>
      {number && <strong>{number}. </strong>}
      {label}
    </span>
    <input
      type="radio"
      name={name}
      value="yes"
      ref={register}
      defaultChecked={patient?.[name] === "yes"}
    />
    <input
      type="radio"
      name={name}
      value="no"
      ref={register}
      defaultChecked={patient?.[name] === "no"}
    />
    <input
      type="radio"
      name={name}
      value="dontknow"
      ref={register}
      defaultChecked={patient?.[name] === "dontknow"}
    />
  </Columns>
);

const QuestionnaireForm = ({ onSubmit, loading, patient }) => {
  const { register, handleSubmit, errors } = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Columns>
        <span></span>
        <label>Yes</label>
        <label>No</label>
        <label>Don't Know</label>
      </Columns>
      <RadioField
        name="question1"
        label="Are you feeling sick today?"
        register={register}
        number="1"
        patient={patient}
      />
      <RadioField
        name="question2"
        label="Have you ever received a dose of COVID-19 vaccine?"
        register={register}
        number="2"
        patient={patient}
      />
      <p>
        <strong>3.</strong> Have you ever had an allergic reaction to:
      </p>
      <RadioField
        name="question3a"
        label="A component of the COVID-19 vaccine, including polyethylene glycol (PEG), which is found in
            some medications, such as laxatives and preparations for colonoscopy procedures"
        register={register}
        small
        patient={patient}
      />
      <RadioField
        name="question3b"
        label="Polysorbate"
        register={register}
        small
        patient={patient}
      />
      <RadioField
        name="question3c"
        label="A previous dose of COVID-19 vaccine"
        register={register}
        small
        patient={patient}
      />
      <RadioField
        name="question4"
        label="Have you ever had an allergic reaction to another vaccine (other than COVID-19 vaccine) or an
            injectable medication?"
        register={register}
        number="4"
        patient={patient}
      />
      <RadioField
        name="question5"
        label="Have you ever had a severe allergic reaction (e.g., anaphylaxis) to something other than a
            component of COVID-19 vaccine, polysorbate, or any vaccine or injectable medication? This would
            include food, pet, environmental, or oral medication allergies."
        register={register}
        number="5"
        patient={patient}
      />
      <RadioField
        name="question6"
        label="Have you received any vaccine in the last 14 days?"
        register={register}
        number="6"
        patient={patient}
      />
      <RadioField
        name="question7"
        label="Have you ever had a positive test for COVID-19 or has a doctor ever told you that you had COVID-19?"
        register={register}
        number="7"
        patient={patient}
      />
      <RadioField
        name="question8"
        label="Have you received passive antibody therapy (monoclonal antibodies or convalescent serum) as
treatment for COVID-19?"
        register={register}
        number="8"
        patient={patient}
      />
      <RadioField
        name="question9"
        label="Do you have a weakened immune system caused by something such as HIV infection or cancer or do
            you take immunosuppressive drugs or therapies?"
        register={register}
        number="9"
        patient={patient}
      />
      <RadioField
        name="question10"
        label="Do you have a bleeding disorder or are you taking a blood thinner?"
        register={register}
        number="10"
        patient={patient}
      />
      <RadioField
        name="question11"
        label="Are you pregnant or breastfeeding?"
        register={register}
        number="11"
        patient={patient}
      />
      <Button type="submit">{loading ? <Loading /> : "Submit"}</Button>
    </form>
  );
};

export default QuestionnaireForm;
