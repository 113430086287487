import React, { useState, useContext, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/functions";
import firebaseApp, { db } from "../../firebase";
import { useForm } from "react-hook-form";
import { AuthContext } from "components/context/Auth";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { AdsClickRounded, BusinessRounded, CampaignRounded } from "@mui/icons-material";
import MuBox from "components/mui/MuBox";
import { LocationContext } from "components/context/Location";

import PromoteBrand from "images/promote_brand.png";
import PromoteClinics from "images/promote_clinics.png";
import MarketingSettings from "./MarketingSettings";
import ZipSettings from "./ZipSettings";
import PromoteFacilitiesSettings from "./PromoteFacilitiesSettings";
import AddressSettings from "./AddressSettings";

const Advertising = () => {
  const { user, userData, subscription, userLoading } = useContext(AuthContext);
  const { location, locationLoaded } = useContext(LocationContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState();
  const [submitted, setSubmitted] = useState();
  const [showSuccess, setShowSuccess] = useState(false);

  const [learnMoreOpen, setLearnMoreOpen] = useState(false);
  const [exampleOpen, setExampleOpen] = useState(false);
  const [addressOpen, setAddressOpen] = useState(false);
  const [marketingOpen, setMarketingOpen] = useState(false);
  const [zipOpen, setZipOpen] = useState(false);
  const [promoteFacilitiesOpen, setPromoteFacilitiesOpen] = useState(false);

  const switchToAddress = () => {
    setAddressOpen(true);
  };

  const switchToBranding = () => {
    setMarketingOpen(true);
  };

  useEffect(() => {
    if (!localStorage.getItem("adsLearnMoreShown")) setLearnMoreOpen(true);

    // Check to see if we should show success message after checkout
    let l = window.location.pathname.split("/");
    if (l[l.length - 1] === "success") setShowSuccess(true);
  }, []);

  return (
    <Box>
      <Box sx={{ mb: 5 }}>
        <Typography variant="h4" component="h2" sx={{ mt: { xs: 1, sm: 5 }, mb: 2 }}>
          Advertising
        </Typography>

        {showSuccess ? (
          <Alert severity="success" sx={{ mt: 3, mb: 4 }}>
            Your advertisements on your selected ZIP codes have been posted.
          </Alert>
        ) : null}

        <Typography variant="body" sx={{ display: "block", maxWidth: "40rem" }}>
          Advertise your brand and facilities on HealthDesk to get them in front of prospective
          patients.{" "}
          <Link sx={{ cursor: "pointer" }} onClick={() => setLearnMoreOpen(true)}>
            Learn more.
          </Link>
        </Typography>
      </Box>

      <Box>
        <Grid container spacing={1}>
          {/* <Grid item xs={12} sm={12} sx={{ p: { xs: 0, sm: 4 }, pb: { xs: 4, sm: 4 } }}>
            <Box
              sx={{
                boxShadow: 1,
                borderRadius: 2,
                p: 4,
                mb: 3,
                textAlign: "center",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Avatar sx={{ bgcolor: "background.paper", margin: "auto" }}>
                <AdsClickRounded color="primary" sx={{ fontSize: "2.5rem" }} />
              </Avatar>

              <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                Promote Your Brand
              </Typography>

              <Typography variant="body2" gutterBottom>
                Promote your brand in front of patients looking for the best locations. Advertise on
                both the HealthDesk Map and our Medical SuperIntelligence.
              </Typography>

              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                }}
              >
                <Button onClick={() => setZipOpen(true)} variant="contained">
                  Choose ZIP Codes
                </Button>
              </Box>
            </Box>
          </Grid> */}

          {/* <Grid item xs={12} sm={12} sx={{ p: { xs: 0, sm: 4 }, pb: { xs: 4, sm: 4 } }}> */}
          <Grid item sx={{ pb: { xs: 4, sm: 4 } }}>
            <Box
              sx={{
                boxShadow: 1,
                borderRadius: 2,
                p: 4,
                mb: 3,
                textAlign: "center",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Avatar sx={{ bgcolor: "background.paper", margin: "auto" }}>
                <BusinessRounded color="primary" sx={{ fontSize: "2.5rem" }} />
              </Avatar>

              <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                Promote Your Facilities
              </Typography>

              <Typography variant="body2" gutterBottom>
                Promote your facilities to feature them on the map for patients looking for
                locations they can trust. Your facilities will have their own category in the map
                filters for easy discoverability.
              </Typography>

              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                }}
              >
                <Button onClick={() => setPromoteFacilitiesOpen(true)} variant="contained">
                  Choose ZIP Codes
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Advertise section */}
      <MuBox
        icon={<CampaignRounded fontSize="large" sx={{ color: "#117aca" }} />}
        title="Branding"
        description={
          <>
            Edit the logo and website link that will be shown in your ads.{" "}
            <Link sx={{ cursor: "pointer" }} onClick={() => setExampleOpen(true)}>
              See an example.
            </Link>
          </>
        }
        links={[
          {
            title: "Edit Branding",
            onClick: () => {
              setMarketingOpen(true);
            },
          },
        ]}
      />

      {/* Learn More popups */}
      <LearnMore
        branding={location?.branding}
        open={learnMoreOpen}
        close={() => setLearnMoreOpen(false)}
      />

      <Example
        branding={location?.branding}
        open={exampleOpen}
        close={() => setExampleOpen(false)}
      />

      {/* Settings dialog popups */}
      <MarketingSettings
        user={user}
        data={userData}
        location={location}
        visible={marketingOpen}
        close={() => setMarketingOpen(false)}
        setSubmitted={setSubmitted}
      />

      <AddressSettings
        user={user}
        data={userData}
        location={location}
        visible={addressOpen}
        close={() => setAddressOpen(false)}
        setSubmitted={setSubmitted}
      />

      <ZipSettings
        user={userData}
        location={location}
        switchToBranding={switchToBranding}
        open={zipOpen}
        close={() => setZipOpen(false)}
      />

      <PromoteFacilitiesSettings
        user={userData}
        location={location}
        switchToAddress={switchToAddress}
        switchToBranding={switchToBranding}
        open={promoteFacilitiesOpen}
        close={() => setPromoteFacilitiesOpen(false)}
      />
    </Box>
  );
};

const LearnMore = ({ branding, open, close }) => {
  return (
    <Dialog maxWidth="lg" open={open} onClose={close} sx={{ textAlign: "center" }}>
      <DialogTitle sx={{ mt: 3, mb: 4 }}>Advertising with HealthDesk</DialogTitle>

      <DialogContent>
        <Grid container>
          <Grid item xs={12} sm={6} sx={{ p: { xs: 0, sm: 4 }, pb: { xs: 4, sm: 4 } }}>
            <Box
              component="img"
              sx={{
                width: "100%",
                maxHeight: { xs: 400, md: 350 },
                maxWidth: { xs: 400, md: 350 },
                borderRadius: "0.5rem",
              }}
              alt="An example of facility branding being promoted."
              src={PromoteBrand}
            />
            <Typography variant="h5" component="h3" sx={{ mt: 3, mb: 2 }}>
              Promote Your Brand
            </Typography>
            <DialogContentText variant="body2">
              Promote your brand in front of patients looking for the best locations. Advertise on
              both the <strong>HealthDesk Map</strong> and our{" "}
              <strong>Medical SuperIntelligence</strong>.
            </DialogContentText>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ p: { xs: 0, sm: 4 } }}>
            <Box
              component="img"
              sx={{
                width: "100%",
                maxHeight: { xs: 400, md: 350 },
                maxWidth: { xs: 400, md: 350 },
                borderRadius: "0.5rem",
              }}
              alt="An example of facility branding being promoted."
              src={PromoteClinics}
            />
            <Typography variant="h5" component="h3" sx={{ mt: 3, mb: 2 }}>
              Promote Your Facilities
            </Typography>
            <DialogContentText variant="body2">
              Promote your facilities to feature them on the map for patients looking for locations
              they can trust. Your facilities will have their own category in the map filters for{" "}
              <strong>easy discoverability</strong>.
            </DialogContentText>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", mb: 4 }}>
        <Button
          onClick={() => {
            localStorage.setItem("adsLearnMoreShown", "true");
            close();
          }}
          autoFocus
          variant="contained"
          size="large"
        >
          Get Started
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Example = ({ branding, open, close }) => {
  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>Advertising with HealthDesk</DialogTitle>

      <DialogContent>
        <DialogContentText variant="body2" sx={{ mb: 2 }}>
          Your branding will appear whenever users are in your ZIP code. Your facility's logo and a
          link to your facility's website will be placed at the top of the{" "}
          <Link href="/" target="_blank">
            facility map
          </Link>{" "}
          and the <Link href="/dashboard/msi">Medical SuperIntelligence</Link>.
        </DialogContentText>

        {branding ? (
          <DialogContentText variant="body2" sx={{ mb: 2 }}>
            Your branding will appear like this:
          </DialogContentText>
        ) : null}

        {branding ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              mt: 3,
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: 700, fontSize: 12, mb: 1 }}>
              Health Care's Help Desk is sponsored by
            </Typography>
            <a href={branding?.website} target="_blank" rel="noopener">
              <img src={branding?.logo} style={{ maxHeight: "90px", maxWidth: "100%" }} />
            </a>
          </Box>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={close} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Advertising;
