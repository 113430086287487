// import Leaflet from "leaflet";
import React, { useState, useEffect, useContext, useCallback } from "react";
import usePosition from "../hooks/usePosition";
import styled from "styled-components";
import Welcome from "./Welcome";
import Marker from "./Marker";
import Loading from "./Loading";
import AdCarousel from "./AdCarousel";
import Modal from "./Modal";
import Header from "./Header";
// import ContactCircle from "./styled/ContactCircle";
import { db } from "../firebase";
import { MapContainer, TileLayer, Rectangle } from "react-leaflet";
import { useMap } from "react-leaflet/hooks";
import { ZipContext } from "./context/Zip";
import { differenceInHours, differenceInMinutes } from "date-fns";

const MapWrapper = styled.div`
  background: #fff;
  position: absolute;
  overflow-y: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 960px;
  margin: 0 auto;
`;

const MapUpdater = ({ coords, updateMap }) => {
  const map = useMap();

  useEffect(() => {
    if (coords) {
      updateMap(map, coords);
    }
  }, [coords, map, updateMap]);

  return null;
};

const SimpleMap = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const { latitude, longitude, error, showPopup, setShowPopup } = usePosition();
  const { groups, setCustomLocation, branding } = useContext(ZipContext);
  const [coords, setCoords] = useState();
  const [filter, setFilter] = useState({ facility: "clinic", rating: 4 });
  const [searchLoaded, setSearchLoaded] = useState(true);
  const [rectangle, setRectangle] = useState();

  const [modalOpen, setModalOpen] = useState({});
  const [modalVisible, setModalVisible] = useState(false);

  const calculateWaitingScore = (score) => {
    if (score === undefined) {
      return undefined;
    }

    if (score <= 1) {
      return 30;
    } else if (score <= 2) {
      return 60;
    } else if (score <= 3) {
      return 120;
    } else if (score <= 5) {
      return 150;
    } else if (score <= 7) {
      return 180;
    } else if (score <= 9) {
      return 240;
    } else {
      return 360;
    }
  };

  const getAdminTime = (times) => {
    const latestTime = times.find((time) => time.waitTime);

    if (latestTime.temp && !!differenceInHours(Date.now(), latestTime.date)) {
      return undefined;
    }

    return latestTime.waitTime;
  };

  const getUserTime = (times) =>
    times.reduce((sum, time) => {
      return sum + Number(time.waitTime);
    }, 0) / times.length;

  const getLocations = useCallback(async (bounds) => {
    const latLocations = [];
    const lngLocations = [];

    await Promise.all([
      db
        .collection("locations")
        .where("lat", "<", bounds.north)
        .where("lat", ">", bounds.south)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            if (doc.data().lat) {
              latLocations.push(doc.data());
            }
          });
        }),
      db
        .collection("locations")
        .where("lng", "<", bounds.east)
        .where("lng", ">", bounds.west)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            if (doc.data().lat) {
              lngLocations.push(doc.data());
            }
          });
        }),
    ]).then(() => {
      const locations = latLocations.filter((locationLat) =>
        lngLocations.some((locationLng) => locationLat.id === locationLng.id)
      );

      // We have the results from firebase, now we just need to process the data and set to state
      const newData = locations;
      const timeLimit = 60 * 60 * 1000 * 3; //hours by which time will no longer be used

      // Average wait times and get other metadata and add to data
      locations.forEach((location, i) => {
        let newLocation = { ...newData[i] };
        newData[i] = newLocation;

        newLocation.email = location.email;
        newLocation.queueEnabled = location.queueEnabled;
        newLocation.queueCap = location.queueCap;
        newLocation.queueLength = location.queue?.length;

        const waitTimes = location.waitTimes;

        if (!waitTimes) {
          newLocation.waitScore = calculateWaitingScore(location.score);

          newData[i] = newLocation;
          return;
        }

        if (waitTimes) {
          // get the newest times first
          const sortedWaitTimes = waitTimes.reverse();

          // find out if this location has any times from HealthDesk admin
          if (waitTimes.find((time) => time.admin)) {
            const { admin } = sortedWaitTimes.find((time) => time.admin);

            newLocation.admin = admin;
          }

          // get the latest data from the dashboard, which includes additional info like if they have mri or not
          if (waitTimes.find((time) => time.dashboard)) {
            const { customPhone, telehealth, beds, icu, lab, xray, ultrasound, ct, mri } =
              sortedWaitTimes.find((time) => {
                return time.dashboard;
              });

            // there's got to be a better way to do this, but here we are
            newLocation.telehealth = telehealth;
            newLocation.customPhone = customPhone;
            newLocation.beds = beds;
            newLocation.icu = icu;
            newLocation.lab = lab;
            newLocation.xray = xray;
            newLocation.ultrasound = ultrasound;
            newLocation.ct = ct;
            newLocation.mri = mri;
          }

          // Take out times > 3 hours ago
          const validWaitTimes = waitTimes.filter(
            (time) => new Date() - time.date < timeLimit && new Date() > time.date
          );

          // If there are no valid wait times, let's get historical data from the database, and if there is none, set as undefined
          if (!validWaitTimes.length) {
            newLocation.waitScore = calculateWaitingScore(location.score);
            return;
          }

          const adminWaitTimes = validWaitTimes.filter((time) => time.admin);

          // Average times if user-submitted, otherwise grab first time
          const finalWaitTime = adminWaitTimes.length
            ? getAdminTime(sortedWaitTimes)
            : getUserTime(validWaitTimes);

          newLocation.lastUpdated = adminWaitTimes.length
            ? adminWaitTimes[0].date
            : validWaitTimes[0].date;

          newLocation.averageWaitTime = finalWaitTime;
          newData[i] = newLocation;
        }
      });

      setData(newData);
      setSearchLoaded(true);
      setLoading(false);
    });
  }, []);

  const updateMap = useCallback(
    (map, newCoords) => {
      setSearchLoaded(false);

      map.setView(coords, map.getZoom());

      // bounds of map
      const bounds = {
        north: newCoords.lat + 0.35,
        south: newCoords.lat - 0.35,
        east: newCoords.lng + 0.35,
        west: newCoords.lng - 0.35,
      };

      setRectangle([
        [bounds.north, bounds.east],
        [bounds.south, bounds.west],
      ]);

      getLocations(bounds);
    },
    [coords, getLocations]
  );

  // Onload, check if we're on a subdomain...
  useEffect(() => {
    const href = window.location.href.split("/");
    switch (href[href.length - 1]) {
      case "chicago":
        setCoords({ lat: 41.881832, lng: -87.623177 });
        break;
      case "nyc":
        setCoords({ lat: 40.73061, lng: -73.935242 });
        break;
      case "desmoines":
        setCoords({ lat: 41.619549, lng: -93.598022 });
        break;
      case "urgent":
        setFilter((filter) => ({ ...filter, facility: "clinic" }));
        break;
      case "emergency":
        setFilter((filter) => ({ ...filter, facility: "emergency" }));
        break;
      default:
        return;
    }
  }, []);

  useEffect(() => {
    // Get locations when geolocation has loaded

    if (latitude) {
      // bounds of map
      const bounds = {
        north: latitude + 0.35,
        south: latitude - 0.35,
        east: longitude + 0.35,
        west: longitude - 0.35,
      };

      setRectangle([
        [bounds.north, bounds.east],
        [bounds.south, bounds.west],
      ]);

      getLocations(bounds);
    }

    // check if this is the first visit, if it is, show welcome modal
    // let visited = localStorage["alreadyVisited"];
    // if (!visited) {
    //   setShowWelcome(true);
    //   localStorage["alreadyVisited"] = true;
    // }
  }, [latitude, longitude, getLocations]);

  const DoTracking = () => {
    var a = String(Math.random()) * 10000000000000;
    new Image().src = "https://pubads.g.doubleclick.net/activity;xsp=4736959;ord=" + a + "?";
    return (
      <img // eslint-disable-line jsx-a11y/alt-text
        src={"https://pubads.g.doubleclick.net/activity;xsp=4736959;ord=1?"}
        width={1}
        height={1}
        border={0}
      />
    );
  };

  return (
    <MapWrapper>
      {loading ? (
        <Loading page />
      ) : (
        <div style={{ height: "100dvh", width: "100%" }}>
          <MapContainer
            attributionControl={false}
            style={{
              height: "100dvh",
              width: "100%",
              zIndex: 0,
            }}
            center={(coords && [coords.lat, coords.lng]) || [latitude, longitude]}
            zoom={13}
            zoomControl={false}
            animate={true}
          >
            <Header
              data={data}
              branding={branding}
              filter={filter}
              setFilter={setFilter}
              setCoords={setCoords}
              setCustomLocation={setCustomLocation}
              updateMap={updateMap}
              searchLoaded={searchLoaded}
              groups={groups}
            />
            <TileLayer url="https://api.maptiler.com/maps/streets/{z}/{x}/{y}.png?key=Qsfg2BU2JRzkowxU2Rw0" />
            {data.map((d) => (
              <Marker
                key={d.id}
                data={d}
                filter={filter}
                setModalOpen={setModalOpen}
                setModalVisible={setModalVisible}
              />
            ))}
            <Rectangle bounds={rectangle} color="#ff0000" fillColor="transparent" />
            <MapUpdater coords={coords} updateMap={updateMap} />
          </MapContainer>
          <AdCarousel branding={branding} groups={groups} />
          <Modal
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            userLocation={{ latitude, longitude }}
            error={error}
          />

          {/* If we can't get the geolocation from the browser, ask the user directly with the Welcome modal */}
          <Welcome
            open={showPopup}
            close={() => setShowPopup(false)}
            setCoords={setCoords}
            setCustomLocation={setCustomLocation}
          />
        </div>
      )}

      <DoTracking />
      {/* {!loading ? (
        <ContactCircle branding={branding} loading={loading} />
      ) : null} */}
    </MapWrapper>
  );
};

export default SimpleMap;
