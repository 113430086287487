import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { db } from "../../firebase";
import capitalize from "../helpers/capitalize";

import Loading from "../Loading";
import Text from "../styled/Text";
import TextAnchor from "../styled/TextAnchor";
import Textarea from "../styled/Textarea";
import Button from "../styled/Button";

const TextSequence = ({ data, vaccine }) => {
  const title = data.title ? capitalize(data.title) : undefined;
  const address = data.address ? capitalize(data.address) : undefined;
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const defaultValues = {
    text1: `Thank you for joining the Virtual Queue at ${title}. You should expect several more texts to guide you through this process.  If your phone dies (they do that), proceed directly to ${title} to check in, and tell them your patient number in the Virtual Queue.`,
    text2: `Please go to this link to complete the virtual registration sequence.`,
    text3: `It’s almost your turn.  Please come in to ${title} and show this text to the welcome desk.  Note: it’s not realistic to think you’ll avoid the waiting room entirely…that’s not how most facilities work.  Still, you’ve saved yourself some time.`,
    text4: `${title} is ready for you to come in. The address is ${address}. Show this text to the welcome desk.`,
  };

  const values = vaccine
    ? data.vaccineTextSequence
      ? {
          text1: data.vaccineTextSequence[0],
          text2: data.vaccineTextSequence[1],
          text3: data.vaccineTextSequence[2],
          text4: data.vaccineTextSequence[3],
        }
      : defaultValues
    : data.textSequence
    ? {
        text1: data.textSequence[0],
        text2: data.textSequence[1],
        text3: data.textSequence[2],
        text4: data.textSequence[3],
      }
    : defaultValues;

  const { handleSubmit, reset, register } = useForm();

  const saveSequence = ({ text1, text2, text3, text4 }) => {
    setLoading(true);
    setSubmitted(false);

    const document = vaccine
      ? { vaccineTextSequence: [text1, text2, text3, text4] }
      : { textSequence: [text1, text2, text3, text4] };

    db.collection("locations")
      .doc(String(data.id))
      .update(document)
      .then(function () {
        setLoading(false);
        setSubmitted(true);
      });
  };

  useEffect(() => {
    reset(values);
  }, [title, vaccine]);

  return title ? (
    <div>
      <h1>{vaccine && "Vaccine "}Text Sequence</h1>

      <form onSubmit={handleSubmit(saveSequence)}>
        <Textarea
          name="text1"
          label="On Virtual Queue Signup"
          register={register}
          rows={6}
        />

        <Textarea
          name="text2"
          label="On Registration"
          register={register}
          rows={3}
        />

        <Textarea
          name="text3"
          label="Next in Queue"
          register={register}
          rows={6}
        />

        <Textarea
          name="text4"
          label="Ready for Patient"
          register={register}
          rows={6}
        />

        <Button type="submit">
          {loading ? <Loading /> : "Save Text Sequence"}
        </Button>
        {submitted ? (
          <Text $center>The text sequence has been saved.</Text>
        ) : null}
        <TextAnchor
          $center
          onClick={() => {
            reset(defaultValues);
          }}
        >
          Reset to Defaults
        </TextAnchor>
      </form>
    </div>
  ) : null;
};

export default TextSequence;
