import React, { useState, useContext } from "react";
import Loading from "./Loading";
import { AuthContext } from "./context/Auth";
import { Navigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";
import firebaseApp from "../firebase";
import { Controller, useForm } from "react-hook-form";

import background from "../bgLarge.jpg";
import AuthWrapper from "./registration/AuthWrapper";
import LogoLarge from "./styled/LogoLarge";
import { Alert, FormHelperText, Link, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const [firebaseErrors, setFirebaseErrors] = useState("");

  const onSubmit = ({ email }) => {
    setLoading(true);
    setFirebaseErrors("");

    // Create user and create database entry for user/booklist
    firebaseApp
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        setLoading(false);
        setSent(true);
      })
      .catch((error) => {
        setLoading(false);
        setFirebaseErrors(error.message);
      });
  };

  const { user } = useContext(AuthContext);

  if (user) {
    return <Navigate to="/dashboard" />;
  }

  if (user !== null) return <Loading />;

  return (
    <AuthWrapper background={background}>
      <LogoLarge />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="email"
          control={control}
          defaultValue={""}
          rules={{
            required: "Email is required.",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Invalid email address.",
            },
          }}
          render={({ field }) => (
            <TextField
              id="email"
              label="Email"
              type="email"
              InputLabelProps={{ shrink: true }}
              variant="standard"
              fullWidth
              error={!!errors?.email}
              helperText={errors?.email?.message}
              sx={{ pb: 1 }}
              {...field}
            />
          )}
        />

        {firebaseErrors ? <FormHelperText error={true}>{firebaseErrors}</FormHelperText> : null}

        <LoadingButton
          type="submit"
          loading={loading}
          disabled={loading}
          variant="contained"
          fullWidth
          size="large"
          sx={{ mt: 2, mb: 3 }}
        >
          Reset Password
        </LoadingButton>
      </form>

      {sent ? (
        <Alert severity="success" sx={{ mb: 3 }}>
          A password reset email has been sent.
        </Alert>
      ) : null}

      <Link component={RouterLink} to="/login" underline="none" variant="body2">
        Go back to login
      </Link>
    </AuthWrapper>
  );
};

export default ResetPassword;
