import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { db } from "../../../firebase";

import Loading from "components/Loading";
import EligibilitySelect from "components/forms/EligibilitySelect";

import Error from "components/styled/Error";
import InputField from "components/styled/InputField";
import Text from "components/styled/Text";
import H2 from "components/styled/H2";
import Button from "components/styled/Button";
import ButtonCaptcha from "components/styled/ButtonCaptcha";
import ShareButtons from "components/styled/ShareButtons";

const QueueReserve = ({
  title,
  locationRef,
  userLocation,
  submitted,
  setSubmitted,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [showPatientModal, setShowPatientModal] = useState(false);
  const { handleSubmit, register, errors } = useForm();

  const onSubmit = ({
    phone,
    email,
    age,
    conditions,
    frontlineEssential,
    otherEssential,
  }) => {
    //If no contact info was provided, or no eligibility was provided throw an error
    if (!phone && !email) {
      setError(true);
      return;
    }

    if (!age && !conditions && !frontlineEssential && !otherEssential) {
      setError("Please fill out your vaccine eligibility.");
      return;
    }
    setError(false);
    setLoading(true);

    const timestamp = String(Date.now());

    const vaccinePatientInfo = [];
    if (conditions) vaccinePatientInfo.push("conditions");
    if (frontlineEssential) vaccinePatientInfo.push("frontline_essential");
    if (otherEssential) vaccinePatientInfo.push("other_essential");

    db.collection("vaccine_reservations")
      .doc(timestamp)
      .set({
        id: timestamp,
        facility: locationRef,
        phone,
        email,
        age,
        vaccinePatientInfo,
        lat: userLocation.latitude,
        lng: userLocation.longitude,
        timestamp: timestamp,
        dateCreated: new Date().toUTCString(),
      })
      .then(() => {
        setLoading(false);
        setSubmitted(true);
      });
  };

  return submitted ? (
    <div>
      <H2>Success</H2>
      <Text $center>
        We will send you an invitation when a spot opens up in a Vaccine Virtual
        Queue that you are eligible for.
      </Text>
      <Text $center>
        Sharing is caring! Please help friends and neighbors get signed up.
      </Text>
      <ShareButtons large />
    </div>
  ) : (
    <div>
      <H2>Vaccine Virtual Queue is Full or Inactive</H2>
      <p>
        Sign up here, and we'll let you know as soon as there is a HealthDesk
        Virtual Queue Vaccine Finder available in your area.
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputField
          type="tel"
          name="phone"
          label="Mobile Phone"
          register={register({
            pattern: {
              value: /^[1-9]\d{2}\s\d{3}\s\d{4}/,
              message: "Invalid phone number.",
            },
          })}
          placeholder="123 456 7890"
          autoComplete="tel"
        />
        {errors.phone && <Error>{errors.phone.message}</Error>}
        <InputField
          name="email"
          type="email"
          label="Email Address"
          register={register({
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Invalid email address.",
            },
          })}
        />
        {errors.email && <Error>{errors.phone.email}</Error>}
        {error && (
          <Error>Please add your mobile number or your email address.</Error>
        )}
        <Button type="button" $small onClick={() => setShowPatientModal(true)}>
          Add your vaccine eligibility
        </Button>
        <Error>{error}</Error>
        <EligibilitySelect
          register={register}
          showPatientModal={showPatientModal}
          setShowPatientModal={setShowPatientModal}
        />
        <Text>
          By providing phone number, you agree to receive electronic
          communication from the HealthDesk (rates and charges may apply)
        </Text>
        <ButtonCaptcha type="submit">
          {loading ? <Loading /> : "Join Virtual Queue"}
        </ButtonCaptcha>
      </form>
    </div>
  );
};

export default QueueReserve;
