import { Typography } from "@mui/material";
import React from "react";

const AccountVerifyEmail = ({ verified }) => {
  if (verified)
    return (
      <>
        <Typography variant="h4" sx={{ mt: { xs: 4, sm: 10 }, mb: 2 }}>
          Your email has been verified
        </Typography>

        <Typography variant="body">
          <p>Thanks for joining HealthDesk. Please wait, you will be redirected shortly.</p>
        </Typography>
      </>
    );
};

export default AccountVerifyEmail;
