import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { db } from "../../firebase";
import firebase from "firebase/compat/app";

import SeatRating from "../SeatRating";
import {
  Alert,
  Box,
  Checkbox,
  CircularProgress,
  FormHelperText,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import MuiPhoneNumber from "material-ui-phone-number";
import { grey } from "@mui/material/colors";
import { AuthContext } from "components/context/Auth";

const AdminTimeForm = ({ data }) => {
  const { user, subscription, userData, userLoading } = useContext(AuthContext);
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [dbError, setDbError] = useState(false);

  const onSubmit = (values) => {
    setLoading(true);

    // Submit different data depending if hospital or urgent care
    const dataObject =
      data.type == "Clinic"
        ? {
            date: Date.now(),
            waitTime: values.time,
            lab: values.lab,
            xray: values.xray,
            ultrasound: values.ultrasound,
            ct: values.ct,
            mri: values.mri,
            //   telehealth: values.telehealth,
            customPhone: values.customPhone,
            admin: true,
            dashboard: true,
          }
        : {
            date: Date.now(),
            waitTime: values.time,
            //   beds: values.beds,
            //   icu: values.icu,
            //   telehealth: values.telehealth,
            customPhone: values.customPhone,
            admin: true,
            dashboard: true,
          };

    if (!subscription) dataObject.temp = true;

    db.collection("locations")
      .doc(String(data.id))
      .update({
        waitTimes: firebase.firestore.FieldValue.arrayUnion(dataObject),
      })
      .then(function () {
        setLoading(false);
        setSubmitted(true);
      })
      .catch(function (error) {
        setLoading(false);
        setDbError(true);
      });
  };

  const capabilities = [
    { id: "lab", title: "Lab" },
    { id: "xray", title: "X-ray (plain films)" },
    { id: "ultrasound", title: "Ultrasound" },
    { id: "ct", title: "CT" },
    { id: "mri", title: "MRI" },
  ];

  return (
    <div className="inner">
      <Typography variant="h4" component="h2" sx={{ mt: { xs: 1, sm: 5 }, mb: 2 }}>
        Facility Details
      </Typography>

      <Typography variant="body" sx={{ display: "block", mt: 1, mb: 4 }}>
        Update the status of your facility's waiting room to let potential patients know how long
        they'll be waiting and the capabilities of your facility.
      </Typography>

      {submitted && !loading && (
        <Alert severity="success" sx={{ mb: 3 }}>
          Thank you. Your estimate has been submitted.
        </Alert>
      )}

      {/* Show loading circle if data is still loading */}
      {!data.id ? (
        <CircularProgress />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ mb: 4 }}>
            <Typography variant="body" sx={{ color: grey[700], display: "block", mb: 1 }}>
              What is the current estimated waiting room volume for your facility?
            </Typography>

            <SeatRating register={register} />

            {errors.time ? <FormHelperText error>{errors.time.message}</FormHelperText> : null}
          </Box>

          {/* If it is a clinic, show on-site capabilities */}
          {data.type == "Clinic" ? (
            <Box>
              <Typography variant="body" sx={{ color: grey[700], display: "block" }}>
                What on-site capabilities do you have at your facility?
              </Typography>

              <List sx={{ width: "100%", maxWidth: 420 }}>
                {capabilities.map(({ id, title }) => {
                  return (
                    <ListItem
                      key={id}
                      secondaryAction={
                        <Checkbox
                          edge="end"
                          defaultChecked={data[id]}
                          {...(register && register(id))}
                        />
                      }
                      disablePadding
                    >
                      <ListItemText id={id} primary={title} />
                    </ListItem>
                  );
                })}
              </List>
            </Box>
          ) : null}

          <Typography variant="body" sx={{ color: grey[700], display: "block", mt: 3, mb: 1 }}>
            Facility Phone Number (For waiting time update notifications)
          </Typography>

          <Box sx={{ maxWidth: 420, mb: 2 }}>
            <Controller
              name="customPhone"
              control={control}
              defaultValue={data?.customPhone || ""}
              render={({ field }) => (
                <MuiPhoneNumber
                  id="customPhone"
                  defaultCountry={"us"}
                  onlyCountries={["us"]}
                  disableCountryCode={true}
                  disableDropdown={true}
                  variant="standard"
                  fullWidth
                  sx={{ mb: 2 }}
                  placeholder="123 456 7890"
                  {...field}
                />
              )}
            />
          </Box>

          {/* Submit button */}
          <LoadingButton
            disabled={loading}
            loading={loading}
            type="submit"
            variant="contained"
            size="large"
          >
            Submit Data
          </LoadingButton>
        </form>
      )}

      {dbError && (
        <Alert severity="error" sx={{ mt: 2 }}>
          Error submitting the form. Please try again later.
        </Alert>
      )}
    </div>
  );
};

export default AdminTimeForm;
