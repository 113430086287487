import { Box, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";

const LegendWrapper = styled.div`
  display: ${({ $schedule }) => ($schedule ? "none" : "flex")};
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 10px;
`;

const Legend = ({ schedule }) => (
  <Box
    sx={{
      display: schedule ? "none" : "flex",
      justifyContent: "space-between",
      marginBottom: 2,
      maxWidth: 420,
    }}
  >
    {["empty", "half", "full", "overflowing"].map((text, i) => (
      <Typography key={i} variant="body2" sx={{ fontWeight: "bold" }}>
        {text}
      </Typography>
    ))}
  </Box>
);

export default Legend;
