import React, { useState, useEffect } from "react";
// import { Snackbar, Button } from "@material-ui/core";
import * as serviceWorker from "../serviceWorker";

const ServiceWorkerWrapper = () => {
  const [showReload, setShowReload] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState(null);

  const onSWUpdate = (registration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate });
  }, []);

  const reloadPage = () => {
    waitingWorker.postMessage({ type: "SKIP_WAITING" });
    setShowReload(false);
    window.location.reload(true);
  };

  return (
    <></>
    // <Snackbar
    //   open={showReload}
    //   message="A new version is available!"
    //   onClick={reloadPage}
    //   anchorOrigin={{ vertical: "top", horizontal: "center" }}
    //   action={
    //     <Button color="inherit" size="small" onClick={reloadPage}>
    //       Reload
    //     </Button>
    //   }
    // />
  );
};

export default ServiceWorkerWrapper;
