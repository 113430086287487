import React, { useEffect, useState } from "react";
import { Alert, Box, Link, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

export default ({ firebase, loading, submitted, resendEmail, dbError }) => {
  useEffect(() => {
    const timer = setInterval(() => {
      firebase.auth().currentUser.reload();

      if (firebase.auth().currentUser.emailVerified) {
        window.location.reload();
      }
    }, 1000);
    return () => clearInterval(timer);
  });

  return (
    <>
      <Typography variant="h4" sx={{ mt: { xs: 4, sm: 10 }, mb: 2 }}>
        Confirm your email
      </Typography>

      {submitted && !dbError ? (
        <Alert severity="success">
          Your confirmation email has been resent.
        </Alert>
      ) : null}

      {dbError ? (
        <Alert severity="error">
          Could not resend email. Please try again later.
        </Alert>
      ) : null}

      <Typography variant="body">
        <p>
          Your application has been submitted and we've just sent you a
          confirmation email. Please verify your email address by clicking the
          link in the email.
        </p>

        <p>
          If you haven't received your email after a few minutes, and it's not
          in your spam folder, click to resend your email below.
        </p>
      </Typography>

      <div>
        <LoadingButton
          loading={loading}
          variant="contained"
          disabled={loading}
          onClick={resendEmail}
        >
          Resend Confirmation Email
        </LoadingButton>

        <Box sx={{ mt: 2 }}>
          <Link
            underline="none"
            href="mailto:support@hlthdsk.com?subject=Confirmation email not being delivered"
          >
            Having problems registering? Please contact us.
          </Link>
        </Box>
      </div>
    </>
  );
};
