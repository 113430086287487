import React, { useState } from "react";
import styled from "styled-components";
import { db } from "../../firebase";
import { format, differenceInMinutes } from "date-fns";
import PatientIdModal from "../styled/PatientIdModal";

const QueueListItem = styled.li`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: ${(props) => props.theme.colors.primary};
  max-height: ${({ $deleted }) => ($deleted ? "0" : "100%")};
  transition: max-height 200ms ease-out;
  overflow: hidden;

  div,
  button {
    flex: 1;
  }

  button,
  div:nth-child(2) {
    max-width: 7em;
    margin-left: 0.5em;
    text-transform: uppercase;
    height: 2.4em;
    font-size: 14px;
    border-radius: 4px;
  }

  .call {
    background: ${(props) =>
      props.called ? "none" : props.theme.colors.secondary};
    border: 1px solid ${(props) => props.theme.colors.secondary};
    color: ${(props) => (props.called ? props.theme.colors.secondary : "#fff")};
    cursor: ${(props) => (props.called ? "initial" : "pointer")};
  }

  .register {
    color: #fff;
    border: 1px solid
      ${(props) =>
        props.registered
          ? props.theme.colors.secondary
          : props.theme.colors.primary};
    background-color: ${(props) =>
      props.registered
        ? props.theme.colors.secondary
        : props.theme.colors.primary};
    text-transform: initial;
  }

  .remove {
    background: none;
    border: none;
    font-size: 20px;
    max-width: 1em;
    color: rgb(252, 58, 48);
    margin-left: 0.5em;
    cursor: pointer;
  }

  .phone {
    flex-basis: 100%;
  }

  .patient {
    @media (max-width: 767px) {
      display: none;
    }
  }

  div:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${(props) => props.theme.colors.primary};
  }
`;

const QueuePatient = ({ data, setData, patient }) => {
  const [deleted, setDeleted] = useState(false);
  const [phoneVisible, setPhoneVisible] = useState(false);
  const [calledTime, setCalledTime] = useState();

  const getDifference = () => {
    const time = calledTime ? calledTime : patient.called;
    return differenceInMinutes(Date.now(), time);
  };

  const deletePatient = (patient) => {
    let newQueue = [...data.queue];
    const index = newQueue.indexOf(patient);
    newQueue.splice(index, 1);

    const batch = db.batch();

    batch.update(db.collection("locations").doc(String(data.id)), {
      queue: newQueue,
    });

    //This was disabled on 2-11-2021. Im gonna leave it here in case Dan changes his mind
    //if (data.queue.length > 1) {
    //  const next = newQueue.sort((a, b) => a.id - b.id)[0];
    //
    //  batch.set(db.collection("messages").doc(), {
    //    to: next.phone,
    //    body: data.textSequence
    //      ? data.textSequence[2]
    //      : `It’s almost your turn.  Please come in to ${data.title} and show this text to the welcome desk.  Note: it’s not realistic to think you’ll avoid the waiting room entirely…that’s not how most facilities work.  Still, you’ve saved yourself some time.`,
    //  });
    //}
    //
    batch.commit().then(() => {
      console.log("deleted");
    });
  };

  const registerPatient = (patient) => {
    let newQueue = [...data.queue];
    const index = newQueue.indexOf(patient);
    const updatedPatient = { ...patient, registrationSent: true };
    newQueue[index] = updatedPatient;

    db.collection("registrations")
      .add({
        location: data.id,
        patient: patient.id,
      })
      .then((doc) => {
        const batch = db.batch();

        batch.update(doc, { id: doc.id });

        batch.update(db.collection("locations").doc(String(data.id)), {
          queue: newQueue,
        });

        const message =
          (data.textSequence
            ? data.textSequence[1]
            : `Please go to this link to complete the virtual registration sequence.`) +
          (data?.healthcare_que?.enabled
            ? ` ${window.location.origin}/registration/${doc.id}`
            : "");

        batch.set(db.collection("messages").doc(), {
          to: patient.phone,
          body: message,
        });

        batch.commit().then(() => {
          console.log("sent");
          setData({ ...data, queue: newQueue });
        });
      });
  };

  const textPatient = (patient, type) => {
    const batch = db.batch();

    let newQueue = [...data.queue];
    const index = newQueue.indexOf(patient);
    const updatedPatient =
      type === "call"
        ? { ...patient, called: Date.now() }
        : { ...patient, registered: Date.now() };
    newQueue[index] = updatedPatient;

    batch.update(db.collection("locations").doc(String(data.id)), {
      queue: newQueue,
    });

    const message = data.textSequence
      ? data.textSequence[3]
      : `${data.title} is ready for you to come in. The address is ${data.address}. Show this text to the welcome desk.` +
        ` You are patient #${patient.id}.`;
    batch.set(db.collection("messages").doc(), {
      to: patient.phone,
      body: message,
    });

    batch.commit().then(() => {
      setData({ ...data, queue: newQueue });
    });
  };

  return (
    <QueueListItem
      registered={patient.registration}
      phone={phoneVisible}
      called={calledTime || patient.called}
      $deleted={deleted}
    >
      <div onClick={() => setPhoneVisible(!phoneVisible)}>
        <span className="patient">Patient</span> #{patient.id}
      </div>
      <div>{format(patient.date, "h:mma")}</div>

      <button
        className="register"
        disabled={patient?.registration || patient?.registrationSent}
        onClick={() => {
          registerPatient(patient);
        }}
      >
        {patient?.registration
          ? "Registered"
          : patient?.registrationSent
          ? "Sent"
          : "Register"}
      </button>

      <button
        className="call"
        disabled={patient?.called}
        onClick={() => {
          if (
            patient?.status?.toUpperCase() !== "ARRIVED" &&
            patient?.status?.toUpperCase() !== "CANCELLED"
          ) {
            textPatient(patient, "call");
            setCalledTime(Date.now());
          }
        }}
      >
        {patient?.status?.toUpperCase() === "ARRIVED" ||
        patient?.status?.toUpperCase() === "CANCELLED"
          ? patient.status
          : calledTime || patient.called
          ? getDifference()
          : "Notify"}
      </button>
      <button
        className="remove"
        onClick={() => {
          setDeleted(true);
          setTimeout(() => {
            deletePatient(patient);
          }, 500);
        }}
      >
        &times;
      </button>
      <PatientIdModal
        patient={patient}
        visible={phoneVisible}
        setVisible={setPhoneVisible}
      ></PatientIdModal>
    </QueueListItem>
  );
};

export default QueuePatient;
