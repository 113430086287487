import React, { useState, useContext } from "react";
import { Alert, Box, Button, Paper, Skeleton, Typography } from "@mui/material";
import { MedicalInformationRounded } from "@mui/icons-material";
import { Link } from "react-router-dom";

const MembershipScreen = ({ subscription }) => {
  if (subscription) return;

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          backdropFilter: "blur(5px)",
          position: "absolute",
          textAlign: "center",
          width: "100%",
          height: "100vh",
          zIndex: 1,
        }}
      >
        <Paper
          sx={{
            p: { xs: 3, md: 10 },
            paddingTop: 9,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxWidth: 800,
            m: "auto",
            marginTop: 5,
          }}
        >
          <MedicalInformationRounded color="primary" sx={{ fontSize: 45, mb: 2 }} />

          <Typography variant="h5" sx={{ mb: 2 }}>
            Upgrade to Use This Feature
          </Typography>

          <Typography variant="body" sx={{ mb: 3 }}>
            When you upgrade, you'll be able to use all of our features, including setting the
            current status of your waiting room, our Virtual Queue, our Waiting Room Scheduler, and
            more.
          </Typography>

          <Link to="/dashboard/upgrade">
            <Button variant="contained">Upgrade now</Button>
          </Link>
        </Paper>
      </Box>
    </Box>
  );
};

export default MembershipScreen;
