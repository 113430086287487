import React, { useState } from "react";
import styled from "styled-components";
import firebase from "firebase/compat/app";
import { db } from "../../../firebase";
import { useForm } from "react-hook-form";

import QueueForm from "../queue/QueueForm";

import ModalWrapper from "../../styled/ModalWrapper";

const CloseModal = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding: 12px;
  font-size: 30px;
  line-height: 20px;
`;

const StyledPatientTypeList = styled.ul`
  li {
    font-size: 12px;
    margin-bottom: 2px;
  }
`;
const PatientTypeList = ({ list }) => {
  if (!list.length) return null;

  const listItems = list.map((item) => <li>{item}</li>);

  return (
    <>
      <p>
        <strong>Patients expected to receive vaccine:</strong>
      </p>
      <StyledPatientTypeList>{listItems}</StyledPatientTypeList>
    </>
  );
};

const Modal = ({
  modalOpen,
  setModalOpen,
  modalVisible,
  setModalVisible,
  userLocation,
}) => {
  const { handleSubmit, register, errors } = useForm();
  const [loading, setLoading] = useState(false);
  const [dbError, setDbError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [emailSent, setEmailSent] = useState();
  const [messageSent, setMessageSent] = useState();

  const capitalize = (string) => {
    var splitString = string.toLowerCase().split(" ");
    for (var i = 0; i < splitString.length; i++) {
      splitString[i] =
        splitString[i].charAt(0).toUpperCase() + splitString[i].substring(1);
    }
    return splitString.join(" ");
  };

  const locationName = modalOpen?.title;
  const locationRef = modalOpen?.id?.toString();

  const onSubmit = ({ time }) => {
    setLoading(true);

    db.collection("locations")
      .doc(locationRef)
      .update({
        waitTimes: firebase.firestore.FieldValue.arrayUnion({
          date: Date.now(),
          waitTime: time,
        }),
      })
      .then(function () {
        setLoading(false);
        setSubmitted(true);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
        setDbError(true);
      });
  };

  const renderExpectedPatientList = () => {
    if (!modalOpen.vaccinePatientInfo) {
      modalOpen.vaccinePatientInfo = [];
    }
    let ret_message = "";
    let ret_list = [];
    if (modalOpen.vaccinePatientInfo.includes("all")) {
      return ["All patients"];
    }
    if (modalOpen.vaccinePatientInfo.includes("conditions")) {
      ret_message =
        "Patients with one or more of the following: Obesity (BMI>30), Type 2 Diabetes, Chronic Kidney Disease, COPD, Heart Conditions, Smoking History, Cancer, Solid Organ Transplant, Sickle Cell Disease\n\n";
      ret_list.push(ret_message);
    }
    if (modalOpen.vaccinePatientInfo.includes("age")) {
      ret_message = `Age ${modalOpen.vaccineAge} and up.\n\n`;
      ret_list.push(ret_message);
    }
    if (modalOpen.vaccinePatientInfo.includes("frontline_essential")) {
      ret_message = `Frontline Essential Workers:

            First Responders (Firefighters, Police)
            
            please Education (teachers, support staff, daycare)
            
            Food & Agriculture
            
            Manufacturing
            
            Corrections workers
            
            US Postal Service workers
            
            Public transit workers
            
            Grocery store workers\n\n`;
      ret_list.push(ret_message);
    }
    if (modalOpen.vaccinePatientInfo.includes("other_essential")) {
      ret_message = `Other Essential Workers:

            Transportation and logistics
            
            Food Service
            
            Shelter & Housing (construction)
            
            Finance
            
            IT & Communication
            
            Energy
            
            Media
            
            Legal
            
            Public Safety (Engineers)
            
            Waste & Wastewater\n\n`;
      ret_list.push(ret_message);
    }

    return ret_list;

    //Todo remove this old code
    switch (modalOpen.vaccinePatient) {
      case "age":
        return `Age ${modalOpen.vaccineAge} and up`;

      case "conditions":
        return "Patients with one or more of the following: Obesity (BMI>30), Type 2 Diabetes, Chronic Kidney Disease, COPD, Heart Conditions, Smoking History, Cancer, Solid Organ Transplant, Sickle Cell Disease";

      case "all":
        return "All patients";

      default:
        return;
    }
  };

  const getFacilityTypeName = () => {
    let facilityType = "";
    {
      if (modalOpen.type === "Clinic") {
        facilityType = "Clinic / Immediate Care";
      } else if (modalOpen.type === "Emergency Department") {
        facilityType = "Emergency Department";
      }
      return facilityType;
    }
  };

  return (
    <ModalWrapper $visible={modalVisible} welcome={modalOpen.welcome}>
      <div>
        <CloseModal onClick={() => setModalVisible(false)}>&times;</CloseModal>
        {submitted ? null : (
          <h3>
            {modalOpen.title && capitalize(modalOpen.title)}
            <br />
            {getFacilityTypeName()}
            <br />
            {modalOpen.vaccineQueueCap > 0 && (
              <>
                Vaccine Available
                <br />
              </>
            )}
          </h3>
        )}

        <PatientTypeList list={renderExpectedPatientList()}></PatientTypeList>

        <QueueForm
          textSequence={modalOpen.textSequence}
          queueNumber={modalOpen.vaccineQueueNumber}
          queueCap={modalOpen.vaccineQueueCap}
          locationName={locationName}
          locationRef={locationRef}
          firebase={firebase}
          db={db}
          userLocation={userLocation}
          submitted={submitted}
          setSubmitted={setSubmitted}
        />
      </div>
    </ModalWrapper>
  );
};

export default Modal;
