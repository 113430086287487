import React, { useState, useEffect, useContext } from "react";
import firebaseApp, { db } from "../../firebase";
import firebase from "firebase/compat/app";
import { AuthContext } from "components/context/Auth";
import {
  differenceInDays,
  differenceInMinutes,
  differenceInSeconds,
} from "date-fns";

export const ChatContext = React.createContext();

function parse(text) {
  var source = /【.+†source】/g;
  var breaks = /<br>/g;
  var html = text.replace(source, "").replace(breaks, "");
  // var html = text.replace(source, "");
  return html;
}

const limit = 120;

export const ChatProvider = ({ children }) => {
  const { user, subscription, userLoading } = useContext(AuthContext);
  const [messages, setMessages] = useState();
  const [thread, setThread] = useState();
  const [rateLimited, setRateLimited] = useState(false);
  const [keyword, setKeyword] = useState();
  const [descOpen, setDescOpen] = useState();

  const newThread = () => {
    setThread();
    setMessages([]);

    const timestamp = Date.now();
    db.collection("chat")
      .doc(user.uid)
      .collection("threads")
      .doc(String(timestamp))
      .set({ messages: [], timestamp })
      .then(() => {
        console.log("New thread created.");
      });
  };

  useEffect(() => {
    if (!user) return;

    db.collection("chat")
      .doc(user.uid)
      .collection("threads")
      .onSnapshot((querySnapshot) => {
        const threads = [];

        querySnapshot.forEach((doc) => {
          threads.push({ data: doc.data(), id: doc.id });
        });

        if (threads.length === 0) {
          setMessages([]);
          return;
        }

        const currentThread = threads.sort(
          (a, b) => Number(a.id) - Number(b.id)
        )[threads.length - 1];
        const currentThreadId = currentThread.id;

        setThread(currentThreadId);

        const newMessages = currentThread.data.messages;
        const filteredMessages = newMessages.map((message) => ({
          ...message,
          message: parse(message.message),
          direction: message.sender === "HealthDesk" ? "incoming" : "outgoing",
        }));

        const totalMessages = [];
        threads.map((thread) => {
          totalMessages.push(...thread.data.messages);
          return;
        });

        // Get all the messages that were created today
        const messagesTodayArray = totalMessages.filter((message) => {
          return differenceInDays(Date.now(), message.created * 1000) === 0;
        });
        const messagesToday = messagesTodayArray.length;

        if (messagesToday >= limit) {
          setRateLimited(true);
        }

        setMessages(filteredMessages);
      });
  }, [user]);

  return (
    <ChatContext.Provider
      value={{
        newThread,
        messages,
        setMessages,
        thread,
        rateLimited,
        limit,
        keyword,
        setKeyword,
        descOpen,
        setDescOpen,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
