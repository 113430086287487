import React, { useState, useEffect, useContext } from "react";
import capitalize from "../../helpers/capitalize";
import firebaseApp, { db } from "../../../firebase";
import Forms from "./Forms";
import Switch from "../../styled/Switch";
import MembershipScreen from "components/dashboard/MembershipScreen";
import { Typography } from "@mui/material";
import { AuthContext } from "components/context/Auth";

const VirtualRegistrationSettings = ({ data, setData, vaccine }) => {
  const { user, userData, subscription } = useContext(AuthContext);

  let currentQueue = vaccine ? data.virtual_que : data.healthcare_que;

  const title = data.title ? capitalize(data.title) : undefined;
  const [uploaded, setUploaded] = useState(false);

  const locationRef = db.collection("locations").doc(String(data.id));

  const onChangeCheckbox = (event) => {
    const name = event.target.name;
    const value = event.target.checked;

    currentQueue[name] = value;

    const document = vaccine ? { virtual_que: currentQueue } : { healthcare_que: currentQueue };

    db.collection("locations")
      .doc(String(data.id))
      .update(document)
      .then(function () {
        console.log("success");
      });
  };

  if (currentQueue === undefined) {
    vaccine ? (data.virtual_que = {}) : (data.healthcare_que = {});
    currentQueue = vaccine ? data.virtual_que : data.healthcare_que;
  }
  // const vaccineCheckboxChanged = (event) => {
  //     const { name } = event.target;

  //     console.log(data);

  //     data.virtual_que[name] = event.target.checked;

  //     setData(data);
  // };
  const enabledChanged = (event) => {
    if (!subscription) return;

    currentQueue.enabled = event.target.checked;
    setData(data);

    const document = vaccine ? { virtual_que: currentQueue } : { healthcare_que: currentQueue };

    db.collection("locations")
      .doc(String(data.id))
      .update(document)
      .then(function () {
        console.log("success");
      });
  };

  return title ? (
    <div>
      <Typography variant="h4" component="h2" sx={{ mt: { xs: 1, sm: 5 }, mb: 2 }}>
        Virtual Registrations
      </Typography>

      <Typography variant="body" sx={{ display: "block", mt: 1, mb: 4 }}>
        Allow users to get notified by text message when it's their turn to be seen in your waiting
        room.
      </Typography>

      <MembershipScreen subscription={subscription} />

      <Switch
        label="Use Virtual Registration?"
        onChange={enabledChanged}
        defaultValue={currentQueue !== undefined ? currentQueue?.enabled : false}
      />

      {currentQueue?.enabled ? (
        <>
          <Forms data={data} vaccine={vaccine} />

          {vaccine ? (
            <>
              <h4>Vaccines Offered:</h4>

              <label>
                <input
                  type="checkbox"
                  name="moderna"
                  defaultChecked={currentQueue?.moderna}
                  onChange={onChangeCheckbox}
                />
                Moderna
              </label>
              <label>
                <input
                  type="checkbox"
                  name="pfizer"
                  defaultChecked={currentQueue?.pfizer}
                  onChange={onChangeCheckbox}
                />
                Pfizer
              </label>
            </>
          ) : null}
        </>
      ) : null}
    </div>
  ) : null;
};

export default VirtualRegistrationSettings;
