import React, { useState } from "react";
import styled from "styled-components";
import Button from "./Button";
import { ReCaptcha } from "react-recaptcha-v3";
const ButtonCaptcha = (props) => {
  return (
    <React.Fragment>
      <Button
        {...props}
        onClick={() => {
          console.log("Captcha Clicked");
        }}
      >
        {props.children}
      </Button>

      <ReCaptcha
        sitekey="6LdFylEaAAAAAFxT6Keio4PQU5sg1jhvn7BE5mPq"
        action="submit"
      />
    </React.Fragment>
  );
};

export default ButtonCaptcha;
