import React from "react";
import { Link } from "react-router-dom";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

const MuListItem = ({ icon, text, secondary, link, onClick, disabled }) => {
  let external = false;
  if (link) {
    const pattern = /^((http|https):\/\/)/;
    external = pattern.test(link);
  }

  const inner = (
    <>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={text} secondary={secondary} />
    </>
  );

  const item = (
    <ListItem disablePadding onClick={onClick} disabled={disabled}>
      {onClick || link ? (
        <ListItemButton selected={window.location.pathname === link}>
          {inner}
        </ListItemButton>
      ) : (
        inner
      )}
    </ListItem>
  );

  // Internal link
  if (link && !external) return <Link to={link}>{item}</Link>;

  // External link
  if (link && external)
    return (
      <Link to={link} target="_blank" rel="noreferrer">
        {item}
      </Link>
    );

  return item;
};

export default MuListItem;
