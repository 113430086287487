import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { differenceInDays } from "date-fns";
import capitalize from "../../helpers/capitalize";

import QueueReserve from "./QueueReserve";
import Loading from "../../Loading";

import Error from "../../styled/Error";
import InputField from "../../styled/InputField";
import Text from "../../styled/Text";
import H2 from "../../styled/H2";
import H3 from "../../styled/H3";
import ButtonCaptcha from "../../styled/ButtonCaptcha";
import ShareButtons from "../../styled/ShareButtons";

const QueueForm = ({
  textSequence,
  queueNumber,
  queueCap,
  locationName,
  locationRef,
  firebase,
  db,
  userLocation,
  submitted,
  setSubmitted,
}) => {
  const title = locationName ? capitalize(locationName) : undefined;
  const [loading, setLoading] = useState(false);
  const { handleSubmit, register, errors } = useForm();
  let lastSignedUp = localStorage["vaccineQueueSubmitted"];

  const onSubmit = ({ phone, email }) => {
    setLoading(true);

    const batch = db.batch();

    batch.update(db.collection("locations").doc(locationRef), {
      vaccineQueueCap: firebase.firestore.FieldValue.increment(-1),
      vaccineQueueNumber: firebase.firestore.FieldValue.increment(1),
      vaccineQueue: firebase.firestore.FieldValue.arrayUnion({
        id: queueNumber || 0,
        date: Date.now(),
        phone,
      }),
    });

    const body =
      (textSequence
        ? textSequence[0]
        : `Thank you for joining the Virtual Queue at ${title}. You should expect several more texts to guide you through this process.  If your phone dies (they do that), proceed directly to ${title} to check in, and tell them your patient number in the Virtual Queue.`) +
      ` You are patient #${queueNumber}.`;

    batch.set(db.collection("messages").doc(), {
      to: phone,
      body: body,
      location: locationRef,
      date: Date.now(),
    });

    //Send message if first in line or close to first when registering
    //const locationData = db.collection("locations").doc(locationRef).get().then((doc) => {
    //    const batch2 = db.batch();
    //    let queData = doc.data().vaccineQueue;
    //    if (queData.length >= 1) {
    //        const next = queData.sort((a, b) => a.id - b.id)[0];
    //
    //        batch2.set(db.collection("messages").doc(), {
    //            to: next.phone,
    //            body: textSequence
    //                ? textSequence[2]
    //                : `It’s almost your turn.  Please come in to ${title} and show this text to the welcome desk.  Note: it’s not realistic to think you’ll avoid the waiting room entirely…that’s not how most facilities work.  Still, you’ve saved yourself some time.`,
    //        });
    //        batch2.commit().then(() => {
    //            setLoading(false);
    //            setSubmitted(true);
    //        });
    //
    //    }
    //});

    batch.commit().then(() => {
      setLoading(false);
      setSubmitted(true);
      localStorage["vaccineQueueSubmitted"] = new Date();
    });
  };

  // Only allow one virtual queue signup a day on production
  if (
    process.env.REACT_APP_FIREBASE_PROJECT_ID === "hlthdsk" &&
    differenceInDays(new Date(), new Date(lastSignedUp)) <= 1
  )
    return null;

  if (!queueCap || queueCap <= 0)
    return (
      <QueueReserve
        title={title}
        locationRef={locationRef}
        userLocation={userLocation}
        submitted={submitted}
        setSubmitted={setSubmitted}
      />
    );

  return (
    <div>
      {submitted ? (
        <div>
          <H2>Success</H2>
          <H3>{title} has reserved a dose for you.</H3>
          <H3>
            You should receive a series of texts to guide you through the
            process.
          </H3>
          <H3>
            If you have problems receiving texts, please proceed to {title}
          </H3>

          <br />
          {/* 
                    <a href="https://interlockhealth.com/hclm" target="_blank">
                        <Button>Create or Update your HealthCard</Button>
                    </a>
                     */}
          <Text>
            * You won't receive a text if your phone isn't on
            <br />* You'll have a 30 minute arrival window after receiving that
            text.
            <br />* Never use this system if you have an emergency. Dial 911
          </Text>
          {/* <ShareButtons large /> */}
        </div>
      ) : (
        <>
          <Text>
            Review your eligibility, then provide your mobile phone number to
            reserve a dose and join the Virtual Queue.
          </Text>
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputField
              type="tel"
              name="phone"
              label="Phone"
              register={register({
                required: "Phone number is required.",
                pattern: {
                  value: /^[1-9]\d{2}\s\d{3}\s\d{4}/,
                  message: "Invalid phone number.",
                },
              })}
              placeholder="123 456 7890"
              autoComplete="tel"
            />
            {errors.phone && <Error>{errors.phone.message}</Error>}
            <Text>
              By providing phone number, you agree to receive electronic
              communication from the HealthDesk (rates and charges may apply)
            </Text>
            <ButtonCaptcha type="submit">
              {loading ? <Loading /> : "Join Virtual Queue"}
            </ButtonCaptcha>
          </form>
        </>
      )}
    </div>
  );
};

export default QueueForm;
