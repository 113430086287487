import React, { useState } from "react";
import styled from "styled-components";
import { db } from "../../firebase";
import { format, fromUnixTime, differenceInMinutes } from "date-fns";
import ModalGeneric from "../ModalGeneric";
import Button from "./Button";

const ImageHover = styled.div`
  height: 100%;
  min-width: 100%;
  background: rgba(255, 255, 255, 0.8);
  background-image: url("${(props) => props.background_image}");
  background-repeat: no-repeat;
  transform: scale(1.7) rotate(${(props) => props.rotation}deg);
  position: absolute;
  display: ${(props) => (props.showZoomedImage ? "initial" : "none")};
  z-index: 5000;
  backgroundposition: ${(props) =>
    props.zoomed_position
      ? "-" +
        props.zoomed_position[0] +
        "px" +
        "-" +
        props.zoomed_position[1] +
        "px"
      : 0};
`;

const PatientIdModal = ({ patient, visible, setVisible }) => {
  const [showZoomedImage, setShowZoomedImage] = useState(false);
  const [zoomedPosition, setZoomedPosition] = useState([0, 0]);

  const [imageRotation, setImageRotation] = useState(0);
  return (
    <ModalGeneric visible={visible}>
      {visible ? (
        <>
          <label>Phone Number:</label>
          <p className="phone">{patient.phone}</p>
          <hr></hr>
          <Button
            $small
            style={{
              display: "inline-block",
              backgroundImage:
                "url(" + require("../../images/32pxrotateleft.png") + ")",
              "background-repeat": "no-repeat",
              "background-position": "center",
              "background-size": "contain",
            }}
            onClick={() => setImageRotation(imageRotation - 90)}
          ></Button>

          <Button
            $small
            style={{
              display: "inline-block",
              backgroundImage:
                "url(" + require("../../images/32pxrotateright.png") + ")",
              "background-repeat": "no-repeat",
              "background-position": "center",
              "background-size": "contain",
            }}
            onClick={() => setImageRotation(imageRotation + 90)}
          ></Button>

          <br></br>
          <label>ID:</label>
          <br></br>
          <ImageHover
            onClick={() => setShowZoomedImage(false)}
            background_image={patient.photoId}
            rotation={imageRotation}
            zoomed_position={zoomedPosition}
            showZoomedImage={showZoomedImage}
            id="box"
          ></ImageHover>
          <img
            style={{
              "min-width": "100%",
              transform: "rotate(" + imageRotation + "deg)",
            }}
            src={patient.photoId}
            onClick={(e) => {
              let xPos = e.clientX - 75,
                yPos = e.clientY - 75;
              setShowZoomedImage(true);
              setZoomedPosition([xPos, yPos]);
            }}
          ></img>
        </>
      ) : (
        <></>
      )}
      <Button
        onClick={() => {
          setVisible(false);
        }}
      >
        Close
      </Button>
    </ModalGeneric>
  );
};

export default PatientIdModal;
