import React from "react";
import { Marker as LeafletMarker } from "react-leaflet";
import L from "leaflet";
import noVaccineMarker from "../../../marker.png";
import vaccineMarker from "../../../vaccine-marker.png";
import redMarker from "images/markers/vaccine-marker-red.png";

const Marker = ({
  title,
  id,
  type,
  filter,
  setModalOpen,
  setModalVisible,
  vaccineQueueNumber,
  vaccineQueueCap,
  vaccinePatientInfo,
  vaccineAge,
  textSequence,
  lat,
  lng,
  regional,
  region,
  currentRegion,
  active,
}) => {
  const filterVisible =
    (filter === "emergency" && type == "Emergency Department") ||
    (filter === "clinic" && type != "Emergency Department") ||
    filter === "all";

  const visible =
    currentRegion === "nm"
      ? !regional
        ? currentRegion === region && filterVisible
        : filterVisible
      : filterVisible;

  const handleClick = () => {
    setModalVisible(true);
    setModalOpen({
      title,
      id,
      type,
      filter,
      setModalOpen,
      setModalVisible,
      textSequence,
      vaccineQueueNumber,
      vaccineQueueCap,
      vaccinePatientInfo,
      vaccineAge,
      location: { latitude: lat, longitude: lng },
    });
  };

  return (
    <LeafletMarker
      position={[lat, lng]}
      title={title}
      onClick={handleClick}
      icon={
        new L.icon({
          iconUrl: active
            ? vaccineQueueCap >= 0
              ? vaccineMarker
              : noVaccineMarker
            : redMarker,
          iconSize: visible ? [28, 50] : [0, 0],
          iconAnchor: [14, 46],
        })
      }
    ></LeafletMarker>
  );
};

export default Marker;
