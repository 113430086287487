import React, { useState, useContext, useEffect } from "react";
import Loading from "../Loading";
import { AuthContext } from "../context/Auth";
import { Navigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import firebaseApp, { db } from "../../firebase";
import { Controller, useForm } from "react-hook-form";
import LinkedInTag from "react-linkedin-insight";
import background from "../../bgLarge.jpg";

import LogoLarge from "../styled/LogoLarge";
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  List,
  Link,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  CoronavirusRounded,
  ForumRounded,
  MedicalServicesRounded,
  MedicationRounded,
  PaidRounded,
} from "@mui/icons-material";
import AuthWrapper from "./AuthWrapper";
import MuListItem from "components/mui/MuListItem";

const ProfessionalRegister = () => {
  const [loading, setLoading] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: "onBlur",
    shouldUnregister: false,
  });
  const [firebaseErrors, setFirebaseErrors] = useState("");
  const [redirect, setRedirect] = useState(false);

  const onSubmit = async ({ email, password }) => {
    setLoading(true);
    setPageLoaded(true);

    // CREATE USER AND ADD TO DATABASE
    // Create user
    firebaseApp
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(({ user }) => {
        const { uid } = user;

        // Send verification email to user
        user
          .sendEmailVerification()
          .then(() => {
            let batch = db.batch();

            // Create user
            const userRef = db.collection("users").doc(uid);

            batch.set(userRef, { uid, role: "professional" });

            batch
              .commit()
              .then(() => {
                setRedirect(true);
                LinkedInTag.track(2177722); // Track purchase with LinkedIn analytics
              })
              .catch((error) => {
                setLoading(false);
                setFirebaseErrors(error.message);
                user.delete();
              });
          })
          .catch((error) => {
            setLoading(false);
            setFirebaseErrors(error.message);
            user.delete();
          });
      })
      .catch((error) => {
        setLoading(false);
        setFirebaseErrors(error.message);
      });
  };

  useEffect(() => {
    LinkedInTag.init(1439474);
  });

  const { user } = useContext(AuthContext);

  if (user && !pageLoaded) {
    return <Navigate to="/dashboard" />;
  }

  if (redirect) {
    return <Navigate to="/dashboard" />;
  }

  if (user !== null && !pageLoaded) {
    return <Loading />;
  }

  return (
    <AuthWrapper background={background} title="The Cost of Staying Ahead">
      <LogoLarge />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="email"
          control={control}
          defaultValue={""}
          rules={{
            required: "Email is required.",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Invalid email address.",
            },
          }}
          render={({ field }) => (
            <TextField
              id="email"
              label="Email"
              type="email"
              InputLabelProps={{ shrink: true }}
              variant="standard"
              fullWidth
              error={!!errors?.email}
              helperText={errors?.email?.message}
              sx={{ pb: 2 }}
              {...field}
            />
          )}
        />

        <Controller
          name="password"
          control={control}
          defaultValue={""}
          rules={{
            required: "Password is required.",
            minLength: {
              value: 8,
              message: "Password must be at least 8 characters long.",
            },
            pattern: {
              value: /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/g,
              message:
                "Password must contain uppercase and lowercase letters, and at least one number or special character.",
            },
          }}
          render={({ field }) => (
            <TextField
              id="password"
              label="Password"
              type="password"
              InputLabelProps={{ shrink: true }}
              variant="standard"
              fullWidth
              error={!!errors?.password}
              helperText={errors?.password?.message}
              sx={{ pb: 1 }}
              {...field}
            />
          )}
        />

        {firebaseErrors ? <FormHelperText error={true}>{firebaseErrors}</FormHelperText> : null}

        <List dense={true}>
          <MuListItem
            icon={<MedicalServicesRounded />}
            text="Case Review"
            secondary="Present a case, develop a management plan."
          />

          <MuListItem
            icon={<ForumRounded />}
            text="Subject Matter"
            secondary="Access Medical SuperIntelligence at a professional level."
          />

          <MuListItem
            icon={<PaidRounded />}
            text="Pay as you go"
            secondary="Sign up for $19/day, $59/month, or $489/year."
          />
        </List>

        <Controller
          name="privacy"
          control={control}
          rules={{
            required: "You must agree to the privacy policy.",
          }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} />}
              label={
                <>
                  I have read and agree to the{" "}
                  <a href="/privacy-policy" target="_blank">
                    Privacy Policy
                  </a>
                  .
                </>
              }
            />
          )}
        />
        {errors?.privacy ? (
          <FormHelperText error={true} sx={{ mb: 1 }}>
            {errors?.privacy?.message}
          </FormHelperText>
        ) : null}

        <Controller
          name="terms"
          control={control}
          rules={{
            required: "You must agree to the terms and services.",
          }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} />}
              label={
                <>
                  I have read and agree to the{" "}
                  <a href="/terms-of-use" target="_blank">
                    Terms and Services
                  </a>
                  .
                </>
              }
            />
          )}
        />
        {errors?.terms ? (
          <FormHelperText error={true} sx={{ mb: 1 }}>
            {errors?.terms?.message}
          </FormHelperText>
        ) : null}

        <LoadingButton
          type="submit"
          loading={loading}
          disabled={loading}
          variant="contained"
          fullWidth
          size="large"
          sx={{ mt: 2, mb: 2 }}
        >
          Sign Up
        </LoadingButton>
      </form>

      <Grid container>
        <Grid item xs>
          <Link component={RouterLink} to="/" underline="none" variant="body2">
            Return to Map
          </Link>
        </Grid>
        <Grid item>
          <Link component={RouterLink} to="/login" underline="none" variant="body2">
            Already have an account? Log in
          </Link>
        </Grid>
      </Grid>
    </AuthWrapper>
  );
};

export default ProfessionalRegister;
