import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { db } from "../../../firebase";
import Popup from "../../Popup";
import EligibilitySelect from "components/forms/EligibilitySelect";
import Loading from "../../Loading";
import Button from "../../styled/Button";
import ButtonCaptcha from "../../styled/ButtonCaptcha";
import Error from "../../styled/Error";
import TextAnchor from "../../styled/TextAnchor";
import Text from "../../styled/Text";
import H2 from "../../styled/H2";
import InputField from "../../styled/InputField";
import LogoLarge from "../../styled/LogoLarge";
import ShareButtons from "components/styled/ShareButtons";

export default ({ latitude, longitude }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [showPatientModal, setShowPatientModal] = useState(false);
  const { handleSubmit, errors, register } = useForm();

  const onSubmit = ({
    phone,
    email,
    age,
    conditions,
    frontlineEssential,
    otherEssential,
  }) => {
    //If no contact info was provided, or no eligibility was provided throw an error
    if (!phone && !email) {
      setError(true);
      return;
    }

    if (!age && !conditions && !frontlineEssential && !otherEssential) {
      setError("Please fill out your vaccine eligibility.");
      return;
    }

    setError(false);
    setLoading(true);

    const timestamp = String(Date.now());

    const vaccinePatientInfo = [];
    if (conditions) vaccinePatientInfo.push("conditions");
    if (frontlineEssential) vaccinePatientInfo.push("frontline_essential");
    if (otherEssential) vaccinePatientInfo.push("other_essential");

    db.collection("vaccine_reservations")
      .doc(timestamp)
      .set({
        id: timestamp,
        phone,
        email,
        age,
        vaccinePatientInfo,
        lat: latitude,
        lng: longitude,
        timestamp: timestamp,
        dateCreated: new Date().toUTCString(),
      })
      .then(() => {
        setLoading(false);
        setSubmitted(true);
      });
  };

  return (
    <Popup>
      <LogoLarge />

      {submitted ? (
        <>
          <Text $center>
            Thank you. You will be notified when a virtual queue opens in your
            area.
          </Text>
          <ShareButtons large />
        </>
      ) : (
        <>
          <p>
            HealthDesk Vaccine Virtual Queues are the best way to manage vaccine
            distribution.
          </p>

          <TextAnchor
            style={{ display: "none" }}
            $center
            href="https://md3c.com/learn-more-for-leaders"
            target="_blank"
          >
            Learn More
          </TextAnchor>
          <H2 style={{ display: "table-row" }}>
            Join Vaccine Virtual Queue Wait List
          </H2>

          <br></br>
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputField
              type="tel"
              name="phone"
              label="Mobile Phone"
              register={register({
                pattern: {
                  value: /^[1-9]\d{2}\s\d{3}\s\d{4}/,
                  message: "Invalid phone number.",
                },
              })}
              placeholder="123 456 7890"
              autoComplete="tel"
            />
            {errors.phone && <Error>{errors.phone.message}</Error>}
            <InputField
              name="email"
              type="email"
              label="Email Address"
              register={register({
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Invalid email address.",
                },
              })}
            />
            {errors.email && <Error>{errors.phone.email}</Error>}
            {error && (
              <Error>
                Please add your mobile number or your email address.
              </Error>
            )}
            <Button
              type="button"
              $small
              onClick={() => setShowPatientModal(true)}
            >
              Add your vaccine eligibility
            </Button>
            <Error>{error}</Error>
            <EligibilitySelect
              register={register}
              showPatientModal={showPatientModal}
              setShowPatientModal={setShowPatientModal}
            />
            <Text>
              By providing phone number, you agree to receive electronic
              communication from the HealthDesk (rates and charges may apply)
            </Text>
            <ButtonCaptcha type="submit">
              {loading ? <Loading /> : "Join Wait List"}
            </ButtonCaptcha>
          </form>
          <br></br>
          <br></br>
          <Link to="/register">
            <span style={{ fontWeight: "bold" }}>
              Heathcare Providers: Register Here
            </span>
          </Link>
          <p>Transparent. Equitable. Efficient.</p>
        </>
      )}
    </Popup>
  );
};
