import { useState, useEffect } from "react";

export default () => {
  const [position, setPosition] = useState({});
  const [error, setError] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const fallback = {
    latitude: 40.703545,
    longitude: -89.579086,
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        ({ coords }) => {
          setPosition({
            latitude: coords.latitude,
            longitude: coords.longitude,
          });
        },
        (error) => {
          // Fallback coordinates, but also show prompt popup asking for zip
          setPosition(fallback);
          setShowPopup(true);
          setError(error.message);
        }
      );
    } else {
      // Fallback coords
      setPosition(fallback);
      setShowPopup(true);
      setError(error.message);
    }
  }, []);

  return { ...position, error, showPopup, setShowPopup };
};
