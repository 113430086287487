import React, { useState, useEffect, useContext } from "react";
import Calendar from "react-calendar";
import { FaChevronLeft, FaChevronRight, FaCalendarAlt, FaCopy, FaPaste } from "react-icons/fa";

import styled from "styled-components";
import firebase from "firebase/compat/app";
import { db } from "../../firebase";
import { useForm } from "react-hook-form";

import { format, addDays, subDays, addHours, startOfDay, isToday, getUnixTime } from "date-fns";

import Loading from "../Loading";
import SeatRating from "../SeatRating";

import Icon from "../styled/Icon";
import Button from "../styled/Button";
import Text from "../styled/Text";
import Columns from "../styled/Columns";
import { AuthContext } from "components/context/Auth";
import { Typography } from "@mui/material";
import MembershipScreen from "./MembershipScreen";

const StyledCalendar = styled(Calendar)`
  max-height: ${(props) => (props.calendarVisible ? "236px" : 0)};
  overflow: hidden;
  transition: max-height 200ms;

  .react-calendar__navigation__prev2-button,
  .react-calendar__navigation__next2-button {
    display: none;
  }

  .react-calendar__month-view__weekdays__weekday {
    text-align: right;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.darkgray};
    padding: 7px;
  }

  button {
    text-align: right;
    background: transparent;
    border: none;
    padding: 7px;
    cursor: pointer;

    &.react-calendar__month-view__days__day--neighboringMonth {
      color: ${(props) => props.theme.colors.darkgray};
    }

    &:hover {
      background-color: ${(props) => props.theme.colors.gray};
    }

    &.react-calendar__tile--active {
      background-color: ${(props) => props.theme.colors.secondary};
      color: ${(props) => props.theme.colors.white};
    }
  }
`;

const SeatRow = styled.div`
  display: flex;
  align-items: center;

  span {
    width: 3em;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.darkgray};
  }
`;

const CurrentDate = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;

  span {
    margin: 0 10px;
  }
`;

const ActionBar = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: ${(props) => props.theme.colors.darkgray};

  span {
    margin: 0 10px;
  }
`;

const hours = [6, 10, 14, 18, 22];

const Scheduler = ({ data, setData, db }) => {
  const { user, userData, subscription } = useContext(AuthContext);

  const [status, setStatus] = useState("none");
  const [date, setDate] = useState(startOfDay(new Date()));
  const [timestamps, setTimestamps] = useState([]);
  const [clipboard, setClipboard] = useState([]);
  const [defaultValues, setDefaultValues] = useState([]);
  const [currentValues, setCurrentValues] = useState([]);
  const [calendarVisible, setCalendarVisible] = useState(false);
  const { handleSubmit, register, setValue, getValues } = useForm();

  const changeDate = (newDate) => {
    if (data?.waitTimes) {
      setDate(newDate);

      // Get each of the 5 new times in the new date
      const newTimes = hours.map((hour) => String(getUnixTime(addHours(newDate, hour))));

      setTimestamps(newTimes);

      const matches = newTimes.map((time) => {
        return data?.waitTimes.find((dataTime) => time === dataTime.date)?.waitTime;
      });

      setDefaultValues(matches);
    }
  };

  const copyTimes = () => {
    const oldValues = getValues();
    let pendingTimes = Object.entries(oldValues);

    // Copy the values, replacing the values with defaultvalues if not selected
    const newTimes = pendingTimes
      .filter((value) => {
        return timestamps.includes(value[0].slice(5));
      })
      .map((time, i) => (time[1] !== null ? time[1] : defaultValues[i] || null));

    setClipboard(newTimes);
  };

  const pasteTimes = () => {
    const pastedValues = timestamps.map((timestamp, index) => {
      return clipboard[index];
    });

    setDefaultValues(pastedValues);

    timestamps.forEach((timestamp, index) => setValue(`time-${timestamp}`, clipboard[index]));

    setCurrentValues(getValues());
  };

  useEffect(() => {
    changeDate(date);
  }, [data]);

  const onSubmit = (values) => {
    if (!subscription) return;

    setStatus("loading");
    console.log(currentValues, values);

    const times = data?.waitTimes ? [...data.waitTimes] : [];

    // Turn all these values into an array with valid times
    Object.keys(values).forEach((key) => {
      if (values[key]) {
        const keyDate = key.substr(5);

        const existingDate = times.findIndex((time) => time.date === keyDate);
        if (existingDate >= 0) times.splice(existingDate, 1);

        times.push({
          date: key.substr(5),
          waitTime: values[key],
          admin: true,
        });
      }
      return;
    });

    // Sort and filter times to remove duplicates and sort by time
    const filteredTimes = Array.from(times.sort((a, b) => a.date - b.time).map((a) => a.date))
      .map((date) => {
        return times.find((a) => a.date === date);
      })
      .sort((a, b) => a.date - b.date);

    setData({ ...data, waitTimes: filteredTimes });

    db.collection("locations")
      .doc(String(data.id))
      .update({
        waitTimes: filteredTimes,
      })
      .then(function () {
        setStatus("submitted");
      })
      .catch(function (error) {});
  };

  return (
    <div className="inner">
      <Typography variant="h4" component="h2" sx={{ mt: { xs: 1, sm: 5 }, mb: 2 }}>
        Scheduled Waiting Room Estimates
      </Typography>

      <Typography variant="body" sx={{ display: "block", mt: 1, mb: 4 }}>
        Set your estimated waiting room volume for days or weeks in advance to let patients know the
        best times to show up.
      </Typography>

      <MembershipScreen subscription={subscription} />

      {data ? (
        <>
          <CurrentDate>
            {!isToday(date) ? (
              <Icon icon={<FaChevronLeft />} onClick={() => changeDate(subDays(date, 1))} />
            ) : null}
            <span>{format(date, "EEE, MMMM do")}</span>
            <Icon icon={<FaCalendarAlt />} onClick={() => setCalendarVisible(!calendarVisible)} />
            <Icon icon={<FaChevronRight />} onClick={() => changeDate(addDays(date, 1))} />
          </CurrentDate>

          <Columns>
            <Button $small $gray onClick={copyTimes}>
              <Icon $small icon={<FaCopy />} /> Copy Times
            </Button>
            <Button disabled={clipboard.length === 0} $small $gray onClick={pasteTimes}>
              <Icon $small icon={<FaPaste />} /> Paste Times
            </Button>
          </Columns>

          <StyledCalendar
            onChange={changeDate}
            value={date}
            calendarVisible={calendarVisible}
            minDate={new Date()}
            minDetail="year"
          />

          <form onSubmit={handleSubmit(onSubmit)}>
            {hours.map((hour, index) => {
              const time = addHours(date, hour);
              const timestamp = getUnixTime(time);

              return (
                <SeatRow key={index}>
                  <span>{format(time, "haaaaa")}</span>
                  <SeatRating
                    key={timestamp}
                    schedule
                    value={currentValues["time-" + timestamp]}
                    date={timestamp}
                    register={register}
                    getValues={getValues}
                    defaultTime={defaultValues[index]}
                  />
                </SeatRow>
              );
            })}
            <Button type="submit">{status === "loading" ? <Loading /> : "Submit Times"}</Button>
            {status === "submitted" ? (
              <Text $center>Your estimates have been submitted.</Text>
            ) : null}
          </form>
        </>
      ) : (
        <Loading page />
      )}
    </div>
  );
};

export default Scheduler;
