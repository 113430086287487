import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
//import 'overlayscrollbars/css/OverlayScrollbars.css';
import App from "./App";
import ServiceWorkerWrapper from "./components/ServiceWorkerWrapper";

ReactDOM.render(
  <>
    <ServiceWorkerWrapper />
    <App />
  </>,
  document.getElementById("root")
);
