import React from "react";
import styled from "@emotion/styled";
import Label from "./styled/Label";
import seat from "../images/chair.png";
import seatFilled from "../chair-filled.png";
import clock from "../images/clock.png";
import { Box } from "@mui/material";
import { AccessTimeRounded } from "@mui/icons-material";

const ColorOverlay = styled.div`
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: ${({ color }) => `hsl(${color} 75% 50%)`};
  mix-blend-mode: screen;
`;

const SeatData = ({ waitTime }) => {
  const options = [30, 60, 120, 150, 180, 240, 360];

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "360px",
        display: "flex",
        margin: "auto",
        mb: 1,
      }}
    >
      {options.map((option, i) => (
        <Box key={i} sx={{ position: "relative", flex: 1 }}>
          <Box
            component="img"
            sx={{ width: 32 }}
            src={option <= waitTime ? seatFilled : seat}
            alt={option <= waitTime ? "Seat Filled" : "Empty Seat"}
          />
          <ColorOverlay color={Math.abs(waitTime / 3.6 - 100)} />
        </Box>
      ))}
      <AccessTimeRounded sx={{ width: 32 }} />
    </Box>
  );
};

export default SeatData;
