import React from "react";
import ReactDOMServer from "react-dom/server";
import { Marker as LeafletMarker } from "react-leaflet";
import L from "leaflet";
import SvgMarker from "./styled/SvgMarker";
import locationMarker from "../images/map-marker.svg";
import locationMarkerQ from "../images/map-marker-q.svg";

const Marker = ({ data, filter, setModalOpen, setModalVisible }) => {
  const { title, averageWaitTime, type, queueEnabled, waitScore, lat, lng, users } = data;

  const handleClick = () => {
    setModalVisible(true);
    setModalOpen({
      ...data,
      phone: data.customPhone,
      videocare: data.telehealth,
      location: { latitude: data.lat, longitude: data.lng },
    });
  };

  const calculateColor = (averageWaitTime, waitScore, users) => {
    if (averageWaitTime !== undefined) {
      // Convert to percentage
      const percentage = (averageWaitTime - 15) / 465;
      // Convert to hue
      const hue = Math.abs(percentage * 100 - 100);
      return hue;
    } else if (waitScore !== undefined) {
      // Convert to hue
      const hue = Math.abs(waitScore / 3.6 - 100);
      return hue;
    } else {
      return -1;
    }
  };

  // is there a star rating? if yes, sort by that, otherwise, ignore
  const showFromRating = data.star ? data.star >= filter.rating : true;

  const isVisible =
    ((filter.facility === "emergency" && type == "Emergency Department") ||
      (filter.facility === "clinic" && type != "Emergency Department") ||
      filter.facility === data.group ||
      filter.facility === "all") &&
    showFromRating;

  if (data.pending) return;

  return (
    <LeafletMarker
      position={[lat, lng]}
      title={title}
      eventHandlers={{
        click: handleClick,
      }}
      icon_={
        new L.icon({
          iconUrl: queueEnabled ? locationMarkerQ : locationMarker,
          iconSize: [29, 50],
          iconAnchor: [14, 50],
        })
      }
      icon={
        new L.divIcon({
          html: ReactDOMServer.renderToString(
            <SvgMarker
              queueEnabled={queueEnabled}
              baseHue={calculateColor(averageWaitTime, waitScore, users)}
              owned={!!users?.length}
              visible={isVisible}
            />
          ),
        })
      }
    />
  );
};

export default Marker;
