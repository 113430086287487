import React, { useContext, useState } from "react";
import { styled } from "@mui/material/styles";
import { AuthContext } from "./context/Auth";
import { FaBars } from "react-icons/fa";
import MuListItem from "./mui/MuListItem";
import MenuIcon from "@mui/icons-material/Menu";
import {
  DescriptionRounded,
  HelpRounded,
  LocalHospitalRounded,
  MedicationRounded,
  VerifiedUserRounded,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Link,
  List,
  Typography,
} from "@mui/material";

const MenuButton = () => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      <IconButton onClick={() => setShowMenu(true)}>
        <MenuIcon />
      </IconButton>
      <MainMenu open={showMenu} close={() => setShowMenu(false)} />
    </>
  );
};

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1, 2),
  ...theme.mixins.toolbar,
}));

const MainMenu = ({ open, close }) => {
  const { user } = useContext(AuthContext);

  return (
    <Drawer open={open} onClose={close}>
      <DrawerHeader sx={{ alignItems: "center" }}>
        <IconButton
          aria-label="open drawer"
          edge="start"
          onClick={close}
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton>
      </DrawerHeader>

      <Divider />

      <List sx={{ width: 300 }}>
        <MuListItem
          icon={<LocalHospitalRounded />}
          text="Add a Facility"
          link="/register"
        />

        <MuListItem
          icon={<HelpRounded />}
          text="Learn More"
          link="https://md3c.com"
        />

        <MuListItem
          icon={<MedicationRounded />}
          text="Medication Discounts"
          link="https://interlockhealth.com/healthcard-basic/"
        />

        <MuListItem
          icon={<DescriptionRounded />}
          text="Terms of Use"
          link="https://hlthdsk.com/terms-of-use"
        />

        <MuListItem
          icon={<VerifiedUserRounded />}
          text="Privacy Policy"
          link="https://hlthdsk.com/privacy-policy"
        />
      </List>

      <Box sx={{ position: "absolute", bottom: 0, p: 3 }}>
        <Typography variant="body2">
          This site is protected by reCAPTCHA and the Google{" "}
          <Link href="https://policies.google.com/privacy">Privacy Policy</Link>{" "}
          and{" "}
          <Link href="https://policies.google.com/terms">Terms of Service</Link>{" "}
          apply.
        </Typography>
      </Box>
    </Drawer>
  );

  // return (
  //   <MainMenuWrapper $visible={visible}>
  //     <CloseButton onClick={toggleMenu} />
  //     <Container>
  //       <Logo />
  //     </Container>
  //     <Container>
  //       {!user ? <NavButton href="/register">Add a Facility</NavButton> : null}

  //       <NavButton href="/msi/register">Medical SuperIntelligence</NavButton>
  //       <NavButton
  //         href="https://md3c.com/"
  //         target="_blank"
  //         rel="noopener noreferrer"
  //       >
  //         Learn More
  //       </NavButton>
  //       <NavButton
  //         href="https://interlockhealth.com/healthcard-basic/"
  //         target="_blank"
  //         rel="noopener noreferrer"
  //       >
  //         Medication Discounts
  //       </NavButton>
  //       <NavButton
  //         href="/terms-of-use/"
  //         target="_blank"
  //         rel="noopener noreferrer"
  //       >
  //         Terms of Use
  //       </NavButton>
  //       <NavButton
  //         href="/privacy-policy/"
  //         target="_blank"
  //         rel="noopener noreferrer"
  //       >
  //         Privacy Policy
  //       </NavButton>
  //       <Credits>
  //         This site is protected by reCAPTCHA and the Google{" "}
  //         <a href="https://policies.google.com/privacy">Privacy Policy</a> and{" "}
  //         <a href="https://policies.google.com/terms">Terms of Service</a>{" "}
  //         apply.
  //       </Credits>
  //     </Container>
  //   </MainMenuWrapper>
  // );
};

export default MenuButton;
