
import styled from "styled-components";
import {db} from "../firebase";
import React, { useState, useEffect } from "react";
const VaccineReservationList = () => {
    const [vaccineReservations, setVaccineReservations] = useState([]);
    const [ vaccineReservationCount, setVaccineReservationCount] = useState(0);
    const getVaccineReservations = async ()=> {
        const vaccine_reservations_snapshot = await db.collection("vaccine_reservations").get();
        const vaccine_reservations_docs = vaccine_reservations_snapshot.docs.map(doc => doc.data());
        const vaccine_reservations_items = vaccine_reservations_docs.map((d,index) => <li key={d.id}>{index+1}: {d.id} - {d.dateCreated}</li>);
        setVaccineReservations(vaccine_reservations_items)
        setVaccineReservationCount(vaccine_reservations_items.length)
    }
    getVaccineReservations();
    return (
        <React.Fragment>
            <h3>Vaccine Reservations: {vaccineReservationCount}</h3>
            <br/>
        <ul>
        {vaccineReservations}
        </ul>
        </React.Fragment>

    )
}
const InfoDump = () => {






    return (
        <React.Fragment>
<VaccineReservationList></VaccineReservationList>

        </React.Fragment>
    );

}

export default InfoDump;