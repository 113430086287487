import React, { useContext, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import {
  CalendarMonthRounded,
  CampaignRounded,
  ChatRounded,
  EmailRounded,
  LogoutRounded,
  MapRounded,
  MedicalInformationRounded,
  PeopleRounded,
  QuestionAnswerRounded,
  ScheduleRounded,
  SettingsRounded,
  SmsRounded,
  VerifiedRounded,
} from "@mui/icons-material";
import Logo from "components/styled/Logo";
import { Navigate, useNavigate } from "react-router-dom";
import { AuthContext } from "components/context/Auth";
import { ChatContext } from "components/context/Chat";
import MuListItem from "components/mui/MuListItem";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const drawerWidth = 300;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1, 2),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const MuDrawer = ({ children, boxRef }) => {
  const { user, subscription, userData, userLoading, logout } = React.useContext(AuthContext);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const handleLogout = async () => {
    await logout();
    return <Navigate to="/login" />;
  };

  const drawer = (
    <div>
      <DrawerHeader sx={{ alignItems: "center" }}>
        <Box sx={{ display: { xs: "none", sm: "block" } }}>
          <Logo />
        </Box>
        <IconButton
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <div onClick={handleDrawerToggle}>
        {userData?.role === "facility" && user.emailVerified ? <FacilityDrawer /> : null}

        {(userData?.role === "patient" ||
          userData?.role === "professional" ||
          userData?.role === "facility") &&
        user.emailVerified ? (
          <PatientDrawer subscription={subscription} userData={userData} />
        ) : null}

        <OnboardingDrawer logout={handleLogout} />
      </div>
    </div>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "#fff",
          boxShadow: "none",
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar sx={{ display: { xs: "flex", sm: "none" } }}>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: { sm: "none" } }}>
            <Logo />
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          margin: "auto",
          maxWidth: "1024px",
          p: 3,
          paddingTop: { xs: "65px", sm: 0 },
          paddingBottom: { xs: "15px" },
          width: { xs: "100%", sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

const OnboardingDrawer = ({ logout }) => {
  return (
    <List>
      <MuListItem icon={<MapRounded />} text="Return to Map" link="/" />
      <MuListItem icon={<LogoutRounded />} text="Log Out" onClick={logout} />
    </List>
  );
};

const PatientDrawer = ({ subscription, userData }) => {
  const navigate = useNavigate();
  const { messages, newThread } = useContext(ChatContext);
  const disabled = !messages || messages?.length < 1;

  const [showNoEmail, setShowNoEmail] = useState(false);

  const handleClose = () => {
    setShowNoEmail(false);
  };

  // Copy chat transcript to clipboard
  const copyChat = async () => {
    try {
      const messagesArray = messages.map(
        (message) => `${message.sender === "user" ? "You" : "HealthDesk"}:\n${message.message}`
      );

      await navigator.clipboard.writeText(messagesArray.join("\n\n"));
      console.log("Content copied to clipboard");
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const sendEmail = () => {
    if (disabled) return;

    const messagesArray = messages.map(
      (message) => `${message.sender === "user" ? "You" : "HealthDesk"}:\n${message.message}`
    );

    window.open(
      "mailto:?subject=HealthDesk Medical SuperIntelligence Transcript&body=" +
        encodeURIComponent(messagesArray.join("\n\n"))
    );

    // Fallback if there is no default mail client
    let t;

    // If successful, reset the timer
    window.addEventListener("blur", () => {
      clearTimeout(t);
    });

    // The browser did not respond after 500ms, so tell the user something's wrong
    t = setTimeout(() => {
      setShowNoEmail(true);
    }, 500);
  };

  const navigateNewThread = () => {
    if (disabled) return;
    if (userData?.role === "facility") {
      navigate("/dashboard/msi");
    } else {
      navigate("/dashboard");
    }
    newThread();
  };

  return (
    <>
      {subscription === "patient" || subscription === "professional" ? (
        <>
          <Dialog open={showNoEmail} close={handleClose}>
            <DialogTitle>We weren't able to open your email client.</DialogTitle>
            <DialogContent>
              <DialogContentText>
                It looks like you don't have a default email client selected. If you'd like to send
                your chat transcript by email, please copy it to your clipboard using the button
                below.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={copyChat}>
                Copy Chat Transcript
              </Button>
              <Button onClick={handleClose} autoFocus>
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <List>
            <MuListItem
              icon={<QuestionAnswerRounded />}
              text="Medical SuperIntelligence"
              link={userData?.role === "facility" ? "/dashboard/msi" : "/dashboard"}
            />

            <MuListItem
              icon={<ChatRounded />}
              text="New Chat"
              onClick={navigateNewThread}
              disabled={disabled}
            />

            <MuListItem
              icon={<EmailRounded />}
              text="Send Chat by Email"
              onClick={sendEmail}
              disabled={disabled}
            />
          </List>

          <Divider />
        </>
      ) : null}

      {subscription || userData?.role === "facility" ? (
        <>
          <List>
            {userData?.role === "facility" && !subscription ? (
              <MuListItem
                icon={<MedicalInformationRounded />}
                text="Upgrade to HLTHDSK Plus"
                link="/dashboard/upgrade"
              />
            ) : null}

            <MuListItem icon={<SettingsRounded />} text="Settings" link="/dashboard/account" />

            {userData?.admin ? (
              <MuListItem
                icon={<VerifiedRounded />}
                text="Location Approval"
                link="/dashboard/admin/approval"
              />
            ) : null}
          </List>

          <Divider />
        </>
      ) : null}
    </>
  );
};

const FacilityDrawer = () => {
  return (
    <>
      <List>
        <MuListItem icon={<ScheduleRounded />} text="Facility Details" link="/dashboard" />

        <MuListItem icon={<CalendarMonthRounded />} text="Schedule" link="/dashboard/schedule" />

        <MuListItem icon={<PeopleRounded />} text="Queue" link="/dashboard/queue" />

        <MuListItem icon={<SmsRounded />} text="Healthcare Texts" link="/dashboard/sequence" />

        <MuListItem icon={<CampaignRounded />} text="Advertise" link="/dashboard/advertising" />
      </List>

      <Divider />
    </>
  );
};

export default MuDrawer;
