import React, { useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import firebaseApp, { db } from "../../../firebase";
import Loading from "../../Loading";
import Text from "../../styled/Text";
import Button from "../../styled/Button";
import Error from "../../styled/Error";
import Columns from "../../styled/Columns";
import InputField from "../../styled/InputField";

const FileUpload = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 4px;
  height: 2.8em;
  font-size: 16px;
  border: 0;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.primary};
  margin: 0.4rem 0;
  padding: 0 1em;
  cursor: pointer;

  input[type="file"] {
    display: none;
  }
`;

const Remove = styled.button`
  background: none;
  border: none;
  font-size: 20px;
  max-width: 1em;
  color: rgb(252, 58, 48);
  margin-left: 0.5em;
  cursor: pointer;
`;

const Forms = ({ data, vaccine }) => {
  const currentQueue = vaccine ? data.virtual_que : data.healthcare_que;
  const [name, setName] = useState();
  const [error, setError] = useState();
  const [uploading, setUploading] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [url, setUrl] = useState();
  const [showForm, setShowForm] = useState(false);

  const removeForm = (index) => {
    vaccine
      ? data.virtual_que.forms.splice(index, 1)
      : data.healthcare_que.forms.splice(index, 1);

    const document = vaccine
      ? { virtual_que: currentQueue }
      : { healthcare_que: currentQueue };

    db.collection("locations")
      .doc(String(data.id))
      .update(document)
      .then(function () {
        console.log("success");
      });
  };

  const handlePDF = (event) => {
    setError(false);
    if (!name) {
      setError(true);
      return;
    }

    setUploading(true);

    const file = event.target.files[0];
    const filePath = `registrations/consent_forms/${Date.now()}.pdf`;

    // Upload PDF
    var storageRef = firebaseApp.storage().ref();

    if (file) {
      storageRef
        .child(filePath)
        .put(file)
        .then((snapshot) => {
          snapshot.ref.getDownloadURL().then((downloadUrl) => {
            setUrl(downloadUrl);
            const newForm = { name: name, url: downloadUrl };
            if (!currentQueue.forms) {
              currentQueue.forms = [];
            }
            currentQueue.forms.push(newForm);

            const document = vaccine
              ? { virtual_que: currentQueue }
              : { healthcare_que: currentQueue };

            db.collection("locations")
              .doc(String(data.id))
              .update(document)
              .then(function () {
                setUploading(false);
                setUploaded(true);
                setShowForm(false);
                console.log("success");
              });
          });
        });
    }
  };

  return (
    <div>
      <h4>Patient Forms</h4>
      {currentQueue.forms?.map((form, i) => (
        <Columns key={i}>
          <Text>
            <a href={form.url} target="_blank">
              {form.name}
            </a>
          </Text>
          <Remove onClick={() => removeForm(i)}>&times;</Remove>
        </Columns>
      ))}

      {uploaded && (
        <Text>
          Your form has been uploaded.{" "}
          <a href={url} target="_blank">
            View your form
          </a>
        </Text>
      )}

      {currentQueue.forms === undefined ||
      !currentQueue.forms?.length ||
      showForm ? (
        <>
          <p>Please upload a consent form and any other applicable forms.</p>
          <Columns>
            <InputField
              label="Form Name"
              name="name"
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <FileUpload>
              {uploading ? <Loading /> : "Upload file (PDF format)"}
              <input
                type="file"
                name="file"
                accept=".pdf" //Fixes Safari bug
                onChange={handlePDF}
                onClick={(event) => (event.target.value = null)}
              />
            </FileUpload>
          </Columns>
          {error && <Error>Form name is required.</Error>}
        </>
      ) : (
        <Button $small onClick={() => setShowForm(true)}>
          Add another form
        </Button>
      )}
    </div>
  );
};

export default Forms;
